<template>
  <div class="ma-0 pa-0" id="dashboard" style="margin-top: -37px !important;">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <div class="ml-auto" style="width:min-content" v-on='on' v-bind="attrs">
             <v-select :menu-props="{ top: false, offsetY: true }" class="ml-auto ma-0 pa-0 text-right select_filter_page" style="width:45px;"
                    v-model="dashboardFilter"
                    :items="dashboardFilters"
                    :label="$t('filter')"
                    dense
                    @change="setFilter()"
                    rounded
                    item-disabled="disabled"
                    hide-details
                    single-line
                    return-object>
                    <template slot="selection" slot-scope="data">
                      <v-icon :disabled="data.item.disabled" class="ma-0 pa-0"> {{data.item.icon}}</v-icon> 
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-icon :disabled="data.item.disabled" class="ma-0 pa-0 mr-3"> {{data.item.icon}}</v-icon> {{data.item.text}}
                    </template>
              </v-select></div>
      </template>
      <span>{{$t("filter")}}</span>
    </v-tooltip>
    <div class="pa-0 ma-0 my-border " id="dashboard-main">
      <div class="px-3 py-2 ma-0 white" style="min-height:calc(100vh - 130px)">

        <!-- Stats block -->
        <stats :requests="requests"></stats>
        <!-- / Stats block -->
        <!-- tables -->
        <taskstable :tasksFiltered="tasksFiltered"></taskstable>
        <requeststable></requeststable>
        <!-- <fileinputdrop
          :allowedTypes="allowedTypes"
          :allowedSize="allowedSize"
          @upload-file="uploadFile"
        ></fileinputdrop> -->

        <!-- / tables -->
      </div>

    </div>
  </div>
</template>

<script>
import stats from "../components/stats";
import requeststable from "../components/request/RequestsTable";
import taskstable from "@/components/task/TasksTable";
/* import fileinputdrop from '../components/FileInputDrop'*/
import { mapActions, mapGetters,mapState } from "vuex";

export default {
  components: {
    stats,
    requeststable,
    taskstable,
    /* fileinputdrop */
  },
  data() {
    return {
      /*  allowedTypes:"text.*|image.*|application.*",
      allowedSize:"500000000" */
      requestFilters:{},
      taskFilters:{},
      dashboardFilter:{ value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
      dashboardFilters: [
        { value:'0', text:localStorage.getItem('language') === 'en'?'My stuff':'Mes affaires',icon:'mdi-account'},
        { value:'1', text:localStorage.getItem('language') === 'en'?'My group':'Mon group',icon:'mdi-account-group',disabled:true},
        { value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
      ],
    };
  },
   computed:{
     ...mapGetters('task', {
      'tasksFiltered':'tasksFiltered'
    }),
      ...mapState('request', {
      requests: 'requests','storeReqFilters':'filters'
    }),
     ...mapState('task', {
      'storeTaskFilters':'filter'
    }),
  },

     watch:{
        storeReqFilters: {
          handler(){
              Object.assign(this.requestFilters, this.storeReqFilters);
          }, deep: true 
          },

        tasksFiltered: {
          handler(){
            Object.assign(this.taskFilters, this.storeTaskFilters);
          }, deep: true 
        }
   },
  async created() {
    Object.assign(this.requestFilters, this.storeReqFilters);
    Object.assign(this.taskFilters, this.storeTaskFilters);
    this.setDashboardFilterValue();
    this.setFilter();
    this.clearFilter();
    this.clearRequestFilters();
    await this.retriveTasks();
    await this.retriveRequests();
    await this.retrieveSearchHistory();
    await this.retriveUsers();
    await this.retriveConfigurations();
    await this.retriveTypes();
    await this.retrieveAddresses();
    await this.deleteRequestItem();
  },
  methods:{
    ...mapActions('task',['retriveTasks','filterTasks','clearFilter']),
    ...mapActions('request', ['retriveRequests','searchRequest','retrieveSearchHistory','deleteRequestItem','clearRequestFilters']),
    ...mapActions([ "retriveUsers","retriveTypes"]),
    ...mapActions('configuration',['retriveConfigurations']),
    ...mapActions('address', ['retrieveAddresses']),

    setFilter(){
      this.setDashboardFilter();
      this.taskFilters.owner=this.dashboardFilter;
      this.requestFilters.owner=this.dashboardFilter;
      this.searchRequest(this.requestFilters);
      this.filterTasks(this.taskFilters);
    },
    setDashboardFilterValue: function() {
      if(JSON.parse(localStorage.getItem("dashboardFilter"))!=null){
        this.clearFilter();
        this.clearRequestFilters();
        this.$vuetify.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
        this.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
      }
      else{
        this.clearFilter();
        this.clearRequestFilters();
        this.dashboardFilter={ value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'};
        this.setFilter()
      }
    },
    setDashboardFilter() {
        this.$vuetify.dashboardFilter = this.dashboardFilters.filter(item=>item.value==this.dashboardFilter.value)[0];
        localStorage.setItem("dashboardFilter", JSON.stringify(this.$vuetify.dashboardFilter));
    },
    
  }
}
</script>

<style>
.dashboard-background {
  background-image: linear-gradient(to right, #cecece40, #cecece10, #cecece40);
}
.select_filter_page .v-input__slot{
  padding: 0 !important;
}
</style>