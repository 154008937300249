var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customlightgray py-3 my-border"},[_c('v-row',{staticClass:"pa-0 ma-0 text-left"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.task.parent_id)?_c('div',{staticClass:"pa-3 ma-auto font-weight-bold clickable hoverable text-left",on:{"click":_vm.goback}},[_c('v-icon',{staticClass:"pa-0 ma-0"},[_vm._v("mdi-dots-horizontal")])],1):_vm._e(),_c('v-treeview',{attrs:{"items":_vm.getItems(),"item-key":"id","color":"dark","open-all":_vm.isopen,"open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:_vm.allTask.filter(function (i){ return i.id==item.id; })[0].status==0?'success--text':''},[_vm._v(_vm._s(item.id)+" mdi-check-circle ")])]}},{key:"label",fn:function(props){return [_c('v-row',{staticClass:"pa-0 ma-0",on:{"click":function($event){return _vm.goto(props.item)}}},[_c('v-col',{staticClass:"my-auto pa-0 text-wrap",attrs:{"cols":"6","md":"8"}},[_c('span',[_vm._v(_vm._s(props.item.name))])]),_c('v-col',{staticClass:"ma-0 pa-0 text-right justify-end",attrs:{"cols":"6","md":"4"}},[_vm._v(_vm._s(props.item.children.length)+" "),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.children)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto pa-auto text-center",attrs:{"disabled":_vm.task.type==2,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDetails(props.item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ma-0 pa-0"},[_vm._v("mdi-plus-box")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Add Task")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.id)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto pa-auto text-center",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.goto(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ma-0 pa-0"},[_vm._v("mdi-location-enter")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Task Details")))])])],1)],1)]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1)],1),_c('v-scroll-x-transition',[_c('subtasksidebar',{directives:[{name:"show",rawName:"v-show",value:(_vm.details),expression:"details"}],attrs:{"details":_vm.details,"task":_vm.editedItem},on:{"setDetails":_vm.setDetails}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }