<template>
  <v-dialog v-model="dialog_add" max-width="600px">
    <template v-slot:activator="{ on, attrs }">         
      <v-btn class="ma-auto pa-auto custom-color-accent" depressed v-bind="attrs" v-on="on">{{$t("newrequest")}}</v-btn>
    </template>

      <v-card class="customoffwhite">
        <v-card-title class="justify-center ma-0 customlightgray">
          <span class="font-weight-light">{{$t("newrequest")}}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="ma-auto pa-auto">
            <v-col cols="12" class="ma-auto pa-auto text-center">
              <v-btn link :to="{ name: 'addRequest' }" class="pa-2 ma-2 elevation-0 custom-color-accent"><v-icon class="pa-0 ma-0 mb-1">mdi-plus</v-icon>{{$t("createnewrequest")}}</v-btn>
            </v-col>
            <v-col cols="12" class="ma-auto pa-auto customoffwhite text-center font-weight-bold">
              <v-divider></v-divider>{{$t("or")}}
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="ma-auto pa-auto text-center">
              <v-form v-on:submit.prevent ref="form" v-model="valid" lazy-validation>
                <v-autocomplete
                  :items="requests"
                  v-model="selectedRequest"
                  item-value="reqCode"
                  item-text="reqCode"
                  return-object
                  :label="$t('clone')"
                  outlined
                  :rules="[rules.required]"
                  clearable
                  prepend-inner-icon="mdi-content-paste"
                  dense
                ></v-autocomplete>
              </v-form>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions class="ma-0">
        <v-spacer></v-spacer>
        <v-btn class="custom-color-accent-text" text @click="dialog_add = false">{{$t("close")}}</v-btn>
        <v-btn class="custom-color-accent-text" text @click="save()">{{$t("save")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState,mapActions} from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      dialog_add: false,
      valid: true,
      selectedRequest:null,
      selectedRequestCopie:{},
      newItem: {
        RequestStatus: "New",
        StatusDate: moment().format("YYYY-MM-DD"),
        DateDue:null,
        org_id: "1",
        request_type: null,
        reqCode: "",
        RequestorId: null,
        RequestLanguagePref: "fr",
        SecurityClass: null,
        ClauseSet: null,
        DateReceived: moment().format("YYYY-MM-DD"),
        DateOnRequest: moment().format("YYYY-MM-DD"),
        DateInfoComplete: moment().format("YYYY-MM-DD"),
        SourceId: null
      },
      rules: {
        required: value => !!value || this.$t('required'),
      },
      files: [],
      due: false,
    };
  },
  computed: {
    ...mapState("request", {
      requests: "requests",
      loading: "loading"
    }),
    ...mapState({
      types: "types",
    }),
  },
  methods: {
      ...mapActions(["increaseSerial"]),
      ...mapActions("request", ["addRequest"]),
      assignReq(){
        Object.assign(this.selectedRequestCopie, this.selectedRequest);
        this.generateReqCode();
        this.selectedRequestCopie.RequestStatus = 'New';
        this.selectedRequestCopie.DateOfClosure = null;
        this.selectedRequestCopie.DateDue = null;
        this.selectedRequestCopie.DateReceived = moment().format("YYYY-MM-DD");
        this.selectedRequestCopie.DateOnRequest = moment().format("YYYY-MM-DD");
        this.selectedRequestCopie.DateInfoComplete = moment().format("YYYY-MM-DD");
      },
      async save(){
        if(this.$refs.form.validate()){
          await this.assignReq();
          this.addRequest(this.selectedRequestCopie).then(
              response => {
                let newItem = response.data;
                this.increaseSerial(newItem.request_type);
                this.$router.push({name: 'requests',params: { id: newItem.id }})
              },
              () => {
                this.$root.$emit("callAlert", {
                  text: this.$t("Something went wrong"),
                  type: "warning",
                  alert: true
                });
              }
          )
        }
      },
      generateReqCode() {

          let type = this.types.filter(item => item.id == this.selectedRequestCopie.request_type)[0];
          this.selectedRequestCopie.request_type_desc = type.description_en;
          var zeros = "";
          let lastId = type.last_used_serial_number + 1;
          for (var i = lastId.toString().length; i < 4; i++) {
            zeros = "0" + zeros;
          }
          this.selectedRequestCopie.reqCode = `${type.request_type}-${moment().year()}-${zeros +
            lastId}`;
          this.reqCode = `${type.request_type}-${moment().year()}-${zeros +
            lastId}`;
        },

      }
};
</script>