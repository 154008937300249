<template>
    <!-- Stats block -->
    <v-row
        style="max-height:100%"
        class="ma-0 pa-0 my-2 "
        height="auto"
        no-gutters>
         <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="ma-0 pa-0 mr-md-2 white">
            <v-row  class="ma-0 customlightgray">
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="my-border ma-0 my-auto py-auto px-0 ">
                      <!-- Counters -->
                      <v-row class="text-center my-auto ma-0">
                        <v-col cols="12" class="ma-0 pa-0" style="position:relative;">
                         <v-select style="position:absolute; top:-13px;left:-20px;width:165px;"
                            v-model="received_select"
                            :items="received_select_items"
                            menu-props="auto"
                            @change="getReceivedRequests()"
                            label="Select"
                            dense
                            rounded
                            append-icon=""
                            hide-details
                            single-line>
                              <template slot="selection" slot-scope="data">
                                <v-icon class="text--secondary"> {{data.item.icon}} </v-icon> <span class="text-sm text--secondary">{{$t(data.item.text)}}</span>
                              </template>
                              <template slot="item" slot-scope="data">
                                <v-icon class="ma-0 pa-0 mr-3"> {{data.item.icon}}</v-icon> <span class="text-sm">{{$t(data.item.text)}}</span>
                              </template>
                         </v-select>
                        </v-col>
                          <v-col cols="12" class="ma-auto pa-0">
                              <v-icon class=" custom-color-accent-text ma-auto" size="30" >mdi-clipboard-list-outline</v-icon>
                          </v-col>
                          <v-col cols="12" class="ma-auto pa-0">
                              <div class="text-sm text--secondary">{{$t("requestsreceived")}}</div>
                              <div class="font-weight-bold text-sm text--secondary pa-0 ma-0"> {{requestsReceived}}
                               <v-progress-circular size="25" v-if="requestsReceived==null" indeterminate  class="custom-color-accent-text"></v-progress-circular>
                              </div>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="my-border ma-0 my-auto py-auto px-0 ">
                      <!-- Counters -->
                      <v-row class="text-center my-auto ma-0">
                        <v-col cols="12" class="ma-0 pa-0" style="position:relative;">
                          <v-select style="position:absolute; top:-13px;left:-20px;width:165px;"
                              v-model="due_select"
                              :items="due_select_items"
                              menu-props="auto"
                              @change="getDueRequests()"
                              label="Select"
                              dense
                              rounded
                              append-icon=""
                              hide-details
                              single-line>
                                <template slot="selection" slot-scope="data">
                                  <v-icon class="text--secondary"> {{data.item.icon}} </v-icon> <span class="text-sm text--secondary">{{$t(data.item.text)}}</span>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-icon class="ma-0 pa-0 mr-3"> {{data.item.icon}}</v-icon> <span class="text-sm">{{$t(data.item.text)}}</span>
                                </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" class="ma-auto pa-0">
                            <v-icon class=" custom-color-accent-text ma-auto" size="30" >mdi-format-list-checks</v-icon>
                        </v-col>
                        <v-col cols="12" class="ma-auto pa-0">
                            <div class="text-sm text--secondary">{{$t("requestsdue")}}</div>
                            <div class="font-weight-bold text-sm text--secondary pa-0 ma-0">{{requestsDue}}
                            <v-progress-circular size="25" v-if="requestsDue==null" indeterminate  class="custom-color-accent-text"></v-progress-circular>
                            </div>
                        </v-col>
                      </v-row>
                  </v-col> 
            </v-row>
          </div>

        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <div class="ma-0 pa-0 ml-md-2 white">
            <v-row  class="ma-0 customlightgray">
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="my-border ma-0 my-auto py-auto px-0 clickable hoverable" id="Hold" @click="filterRequests({text:$t('onhold'),value:'Hold'})">
                      <!-- Counters -->
                      <v-row class="text-center my-auto ma-0">
                          <v-col cols="12" class="ma-auto pa-0">
                              <v-icon class=" custom-color-accent-text ma-auto" size="30" >mdi-timer-outline</v-icon>
                          </v-col>
                          <v-col cols="12" class="ma-auto pa-0">
                              <div class="text-sm text--secondary">{{$t("onhold")}}</div>
                              <div class="font-weight-bold text-sm text--secondary pa-0 ma-0">{{requestsOnHold}}
                               <v-progress-circular size="25" v-if="requestsOnHold==null" indeterminate  class="custom-color-accent-text"></v-progress-circular>
                              </div>
                          </v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="my-border ma-0 my-auto py-auto px-0 clickable hoverable" id="active" @click="filterRequests({text:$t('active'),value:'active'})">
                      <!-- Counters -->
                      <v-row class="text-center my-auto ma-0">
                          <v-col cols="12" class="ma-auto pa-0">
                              <v-icon class=" custom-color-accent-text ma-auto" size="30" >mdi-timer-sand</v-icon>
                          </v-col>
                          <v-col cols="12" class="ma-auto pa-0">
                              <div class="text-sm text--secondary">{{$t("active")}}</div>
                              <div class="font-weight-bold text-sm text--secondary pa-0 ma-0">{{requestsActive}}
                              <v-progress-circular size="25" v-if="requestsActive==null" indeterminate  class="custom-color-accent-text"></v-progress-circular>
                              </div>
                          </v-col>
                      </v-row>
                  </v-col> 
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="my-border ma-0 my-auto py-auto px-0 clickable hoverable" id="late" @click="filterRequests({text:$t('late'),value:'late'})">
                      <!-- Counters -->
                      <v-row class="text-center my-auto ma-0">
                          <v-col cols="12" class="ma-auto pa-0">
                              <v-icon class=" custom-color-accent-text ma-auto" size="30" >mdi-clipboard-alert</v-icon>
                          </v-col>
                          <v-col cols="12" class="ma-auto pa-0">
                              <div class="text-sm text--secondary">{{$t("late")}}</div>
                              <div class="font-weight-bold text-sm text--secondary pa-0 ma-0">
                                <v-progress-circular size="25" v-if="requestsLate==null" indeterminate  class="custom-color-accent-text"></v-progress-circular>
                                {{requestsLate}}</div>
                          </v-col>
                      </v-row>
                  </v-col>
            </v-row>
          </div>

        </v-col>

      <!-- / Counters -->

      <!--  graph -->
    
      <!-- /  graph -->
       

    </v-row>
    <!-- / Stats block -->
</template>

<script>

import { mapActions,mapState,mapGetters } from "vuex";
import moment from "moment";
export default {
  props:['requests'],
  name: 'Stats',
  metaInfo: {
    title: 'Stats'
  },
  computed:{
    ...mapState('request',{
      'stats':'stats','storeReqFilters':'filters'
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
      ...mapGetters({
      getTypes:"getTypes"
    }),
  },
  data() {
    return {
      requestFilters:{
           owner:JSON.parse(localStorage.getItem("dashboardFilter")),
           status:[{text:this.$t("active"),value:"active"}],
           clause:{value:"",text:""},
           reqid:{value:"",text:""},
           requester:{value:"",text:""},
           eventype:[],
           original:"",
           releaseFlag:{value:"",text:""},
           reqsource:{value:"",text:""},
           receiveddaterange:[],
           duedaterange:[],  
           closeddaterange:[],
      },
      reqs:[],
      received_select:  { text:'today',icon:'mdi-calendar-today'},
      received_select_items: [
        { text:'today',icon:'mdi-calendar-today'},
        { text:'thisweek',icon:'mdi-calendar-week'},
        { text:'thismonth',icon:'mdi-calendar-month'},
        { text:'thisyear',icon:'mdi-calendar-blank-multiple'},
      ],
      requestsReceived:0,
      due_select: { text:'today',icon:'mdi-calendar-today'},
      due_select_items: [
        { text:'today',icon:'mdi-calendar-today'},
        { text: 'thisweek',icon:'mdi-calendar-week'},
        { text: 'thismonth',icon:'mdi-calendar-month'},
        { text: 'nextmonth',icon:'mdi-calendar-month-outline'},
      ], 
      dashboardFilters: [
        { value:'0', text:localStorage.getItem('language') === 'en'?'My stuff':'Mes affaires',icon:'mdi-account'},
        { value:'1', text:localStorage.getItem('language') === 'en'?'My group':'Mon group',icon:'mdi-account-group',disabled:true},
        { value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
      ],
      requestsDue:0,
      requestsOnHold:0,
      requestsLate:0,
      requestsActive:0,
    }
  },
  created(){
    this.clearRequestFilters();
      this.received_select=this.stats.requestsRecievedDateFilter;
      this.due_select=this.stats.requestsDueDateFilter;
      this.searchRequest(this.requestFilters);
      Object.assign(this.requestFilters, this.storeReqFilters);
      Object.assign(this.reqs, this.requests);
      if(this.requestFilters.owner==0){
         this.reqs=this.requests.filter(a => a.ATIPOfficerId == JSON.parse(localStorage.getItem('user')).id);
      }
      this.getDueRequests();
      this.getReceivedRequests();
      this.getOtherStats();
  },
  mounted(){
       ['active','Hold','late'].forEach(element => { this.requestFilters.status.map(a=>a.value).includes(element)? document.getElementById(element).classList.add("selected"):document.getElementById(element).classList.remove("selected")});
  },
  watch:{
    storeReqFilters: {
        handler(){
          this.requestFilters={}
          Object.assign(this.requestFilters, this.storeReqFilters);
          ['active','Hold','late'].forEach(element => { this.requestFilters.status.map(a=>a.value).includes(element)? document.getElementById(element).classList.add("selected"):document.getElementById(element).classList.remove("selected")});
          if(this.requestFilters.owner==0){
              this.reqs=this.requests.filter(a => a.ATIPOfficerId == JSON.parse(localStorage.getItem('user')).id);
          }
          else{
            Object.assign(this.reqs, this.requests);
          }
          this.getDueRequests();
          this.getReceivedRequests();
          this.getOtherStats();
        }, deep: true 
    },
    requests(){
       Object.assign(this.reqs, this.requests);
        this.getDueRequests();
        this.getReceivedRequests();
        this.getOtherStats();
    },

  },
 methods:{
   filterRequests(f){
     if(!this.requestFilters.status.map(a=>a.value).includes(f.value)){
      this.requestFilters.status.forEach(element => {  document.getElementById(element.value)?.classList.remove("selected");  } )
      document.getElementById(f.value).classList.add("selected"); 
      this.requestFilters.status=this.requestFilters.status.filter(st=>st.value!="active" && st.value!="Hold" && st.value!="late");
      this.requestFilters.status.push({text:f.text,value:f.value});
      this.searchRequest(this.requestFilters);
     }
     else{
        this.requestFilters.status.forEach(element => {  document.getElementById(element.value)?.classList.remove("selected");  } )
        this.requestFilters.status=this.requestFilters.status.filter(st=>st.value!="active" && st.value!="Hold" && st.value!="late");
        this.searchRequest(this.requestFilters);
     }

  }, 
  getOtherStats(){
    this.requestsOnHold= this.reqs.filter(item => item.RequestStatus == "Hold").length;
    this.requestsLate= this.reqs.filter(request =>  (moment(this.calculateDueDate(request)).diff(moment(), "days") < 0 && request.DateOfClosure == null)).length
    this.requestsActive=this.reqs.filter(request => {if (request.DateOfClosure == null)return request}).length;
  },
    getReceivedRequests(){
    if(this.received_select=="today")
    this.requestsReceived=this.reqs.filter(request =>moment().isSame(moment(request.DateInfoComplete), 'day')).length;
    if(this.received_select=="thisweek")
    this.requestsReceived=this.reqs.filter(request => moment().isSame(moment(request.DateInfoComplete), 'week')).length;
    if(this.received_select=="thismonth")
    this.requestsReceived=this.reqs.filter(request => moment().isSame(moment(request.DateInfoComplete), 'month')).length;
    if(this.received_select=="thisyear")
    this.requestsReceived=this.reqs.filter(request => moment().isSame(moment(request.DateInfoComplete), 'year')).length;
   },
    getDueRequests(){
    if(this.due_select=="today")
    this.requestsDue=this.reqs.filter(request =>moment().isSame(moment(this.calculateDueDate(request)), 'day')).length;
    if(this.due_select=="thisweek")
    this.requestsDue=this.reqs.filter(request =>moment().isSame(moment(this.calculateDueDate(request)), 'week')).length;
    if(this.due_select=="thismonth")
    this.requestsDue=this.reqs.filter(request =>moment().isSame(moment(this.calculateDueDate(request)), 'month')).length;
    if(this.due_select=="nextmonth")
    this.requestsDue=this.reqs.filter(request =>moment().add(1,'month').isSame(moment(this.calculateDueDate(request)), 'month')).length;
   },
    calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      return date;
    },



    
       ...mapActions('request', ['setStatsRecievedFilter','setStatsDueFilter','searchRequest','clearRequestFilters']),
 },
}
</script>