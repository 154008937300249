<template >
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
    <div class=" my-border">  
    <v-card-title class="py-0 my-0 customlightgray">
      <v-row class="ma-0 pa-0 py-2 justify-center text-left">
        <v-col class="pa-0 ma-auto" cols="4" sm="4" md="4" lg="4" order="1" order-md="1">
          <div class="font-weight-medium text-left text--secondary">  
            <div class="ma-auto pa-auto">
              <span class="ma-0 pa-1">{{$t("tasks")}}</span>
              <span style=" border-radius: 22px; font-size:0.6em;"
                  class="custom-color-accent pa-1">{{tasksFiltered.length}}
              </span>
            </div>
          </div>
        </v-col>
        <v-col  class="ma-0 pa-0 text-center"  order="3" order-md="2"
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4">

                   <v-text-field
                    v-model="search"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
        </v-col>
        <v-col class="text-right pa-0 my-2 ma-md-auto" cols="8" sm="8" md="4" lg="4" order="2" order-md="3">
          <v-btn class="custom-color-accent mx-1 mx-sm-1 pa-auto" @click="kanban=!kanban" depressed>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on='on' v-bind="attrs" v-if="!kanban">mdi-view-week-outline</v-icon>
                <v-icon v-on='on' v-bind="attrs" v-else>mdi-format-list-bulleted</v-icon>
              </template>
              <span v-if="!kanban">{{$t("kanbanview")}}</span>
              <span v-else>{{$t("listview")}}</span>
            </v-tooltip>
          </v-btn>
         <v-btn v-if="this.$checkPermission('Tasks.Create')" class="ma-auto pa-auto custom-color-accent" depressed @click="openInSame(filters.requestId[0]?{name:'addTask',params:{id:filters.requestId[0].value}}:{name:'addTask'})">{{$t("newtask")}}</v-btn>
        </v-col>
      </v-row>
      <!-- dialog advanced search -->
      <v-dialog v-model="dialog_adv" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row class="ma-0">
                <v-col cols="12" class="ma-0 py-0">
                  <v-text-field class="ma-0" :label="$t('title')" :placeholder="$t('title')" dense outlined v-model="filters.title"></v-text-field>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    :label="$t('labels')"
                    :placeholder="$t('labels')"
                    small-chips
                    outlined
                    multiple
                    dense
                    clearable
                    v-model="filters.label"
                    :items="getLookup('TaskLabel')"
                    autowidth
                    return-object
                  >
                    <template v-slot:selection="data">
                      <v-chip :input-value="data.selected" small :color="setcolor(data.item.value)">
                        <strong>{{ data.item.text }}</strong>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col> 
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    :label="$t('status')"
                    :placeholder="$t('status')"
                    small-chips
                    multiple
                    outlined
                    dense
                    clearable
                    v-model="filters.status"
                    :items="getLookup('TaskStatus')"
                    autowidth
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    clearable
                    outlined
                    v-model="filters.requestId"
                    :items="requests.map(request=>{return {value:request.id,text:request.reqCode}})"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    clearable
                    outlined
                    v-model="filters.assignee"
                    :items="users.map(user=>{return {value:user.id,text:user.first_name+' '+user.last_name}})"
                    :label="$t('assignee')"
                    :placeholder="$t('assignee')"
                    dense
                   return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-autocomplete
                    multiple
                    clearable
                    outlined
                    v-model="filters.type"
                    :items="getLookup('TaskType')"
                    :label="$t('type')"
                    :placeholder="$t('type')"
                    dense
                   return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dateRecieved"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('receiveddaterange')"
                        :placeholder="$t('receiveddaterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dateRecieved" :max="nowDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.dueDate"
                        multiple
                        chips
                        small-chips
                        outlined
                        dense
                        :label="$t('duedaterange')"
                        :placeholder="$t('duedaterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.dueDate"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0 py-0">
                  <v-menu
                    class="ma-0"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="filters.closeDate"
                        multiple
                        chips
                        dense
                        clearable
                        outlined
                        small-chips
                        :label="$t('closeddaterange')"
                        :placeholder="$t('closeddaterange')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{ data.item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker range v-model="filters.closeDate"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 py-0">
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog_adv = false">{{$t("close")}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="findTasks()">{{$t("search")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- /dialog advanced search -->
    </v-card-title>
    <v-divider  v-show="filterList.length>0"></v-divider>    
    <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
      <v-col class="pa-0 ma-0" cols="12">
        <v-scroll-x-transition group hide-on-leave>
          <v-tooltip v-for="(f, i) in filterList" :key="i" right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip  v-on='on' v-if="i>0" v-bind="attrs"
                class="ma-1"
                color="custom-color-accent"
                close
                small
                close-icon="mdi-delete"
                @click:close="removeFilter(f)" >
                  <span class="text-capitalize">{{f}}</span>
              </v-chip> 
              <v-chip v-else
                class="ma-1"
                color="custom-color-accent"
                small >
                  <span class="text-capitalize">{{f}}</span>
              </v-chip>
            </template>
            <span>{{$t("removefilter")}}</span>
          </v-tooltip>
        </v-scroll-x-transition>
      </v-col>
    </v-row> 
    <v-divider></v-divider>
    <v-scroll-x-transition >
    <kanban v-show="kanban" @setDetails="setDetails" @showDetails="showDetails" @addTask="openInSame(filters.requestId[0]?{name:'addTask',params:{id:filters.requestId[0].value}}:{name:'addTask'})" :tasks="tasksFiltered"  :selected_task="selected_task" class="customoffwhite pa-0 ma-0"></kanban>

    </v-scroll-x-transition >
    <div v-if="boxID!=''" @mouseleave="resetBoxID" class="preview-box">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-0 ma-0 text-right">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="margin-bottom :-60px !important;margin-right:10px" icon v-on='on' v-bind="attrs" @click.stop link :href="getBoxLink()" target="_blank">
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </template>
              <span>{{$t("openinnew")}}</span>
            </v-tooltip>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0"><iframe :src="getBoxLink()"  class="preview"></iframe></v-col>
      </v-row>
    </div>
    <v-data-table :mobile-breakpoint="1081"  v-show="!kanban"
      :headers="headers"
      :items="tasksFiltered"
      v-model="selected_task"
      :items-per-page="tasksRows"
      class="elevation-0 customoffwhite pa-0 text--secondary ma-0"
      :search="search"
      :loading-text="$t('Loading')"
      :loading="loading"
      :footer-props="{
           'items-per-page-text':$t('rowsperpage')}"
      item-key="id"
      sort-by="id"
      :sort-desc="true"
      expanded.sync
      show-expand
      @click:row="showDetails"
    > 
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ }">
        {{$t(header.text)}}
      </template>
      <template v-slot:[`item.title`]="{item}">
        <span v-if="item.title.length<20" :class="multitask=='true'?'preview-link custom-color-accent-text':''" @mouseover="multitask=='true'?setBoxID(item.id):''">{{ item.title }}</span>
        <span v-else :class="multitask=='true'?'preview-link custom-color-accent-text':''" @mouseover="multitask=='true'?setBoxID(item.id):''">{{ item.title.substring(0,20)+".." }}</span>
      </template>
      <template v-slot:[`item.request_id`]="{item}">
        {{ getRequestCode(item.request_id) }}
      </template>
      <template v-slot:[`item.created_at`]="{item}">{{ item.created_at | formatDate }}</template>
      <template v-slot:[`item.due_date`]="{item}">{{ item.due_date | formatDate }}</template>
      <template v-slot:[`item.date_completed`]="{item}">{{ item.date_completed | formatDate }}</template>
      <template v-slot:[`item.assigned_to`]="{item}"><span class="text-capitalize">{{ getUser(item.assigned_to) }}</span></template>
      <template v-slot:no-data> {{ $t("noDataAvailable") }}</template>
      <template v-slot:expanded-item="{ headers,item }" elevation="0">
        <td class="pa-0 ma-0 my-side-border" :colspan="headers.length" elevation="0">
          <div v-if="item.type==2" justify="center" class="text-center my-12"> 
            <div class="display-1 mb-8 mx-auto pa-auto text-center" align="center"> 
              <div class=" ma-auto">{{$t("waitingforapproval")}}</div><div class="dot-flashing ma-auto"></div>
            </div>
             <div v-if="$checkPermission('Tasks.Approve')">
               <v-textarea
                  grow
                  outlined
                  class="mx-4"
                  v-model="reason"
                  :label="$t('Reason')"
                  :placeholder="$t('Reason')"
                ></v-textarea>
                <v-btn class="ma-1" light depressed @click="reject(item)">
                    {{$t('Reject')}}
                    <v-icon right>
                      mdi-cancel
                    </v-icon>
                </v-btn>
                <v-btn  class="ma-1 custom-color-accent" depressed @click="approve(item)"> 
                    {{$t('approve')}} 
                    <v-icon right>
                       mdi-checkbox-marked-circle
                    </v-icon>   
                </v-btn>
            </div>
          </div>
          <subTasks v-else @setTask="redirectTask" :task="item" :isopen="false"></subTasks>
        </td>
      </template>

      <template class="pa-0 ma-0" v-slot:[`item.label`]="{ item }">
        
        <v-chip v-if="JSON.parse(item.label) && JSON.parse(item.label).length>0"
          class="pa-0 ma-0 mx-1 justify-center status-chip"
          small
          :color="setcolor(JSON.parse(item.label)[0])"
        >
          <span class="pa-0 ma-0 font-weight-black " >{{ getStatusLookup('TaskLabel', item.label)[0] }}</span>
        </v-chip>
        <v-chip small class="gray pa-2 ma-0" v-if="JSON.parse(item.label) && JSON.parse(item.label).length>1">
        <span >+{{JSON.parse(item.label).length-1}}</span>
        </v-chip>
      </template>
      <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
        <v-row class="ma-0 pa-0 text-right">
          <v-col cols="12" class="ma-0 pa-0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on='on' v-bind="attrs">
                <v-icon @click.stop="redirectTask(item.id)">mdi-location-enter</v-icon>
              </v-btn>
            </template>
            <span>{{$t("Task Details")}}</span>
          </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    </div>
    <!-- Edit task section -->
    <v-scroll-x-transition >
      <tasksidebar v-show="details" key="trans1" @setDetails="setDetails" :task="editedItem" :details="details"></tasksidebar>
    </v-scroll-x-transition>
    <!-- /Edit task section -->
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import subTasks from "@/components/task/SubTasks";
import tasksidebar from "@/components/task/TaskSidebar";
import kanban from "@/components/task/TaskKanban";

export default {
  name: "Task",
  props: ["tasksFiltered"],
  components:{
        subTasks,
        tasksidebar,
        kanban,
  },
  data: () => ({
    nowDate: new moment().toISOString(),
    filterList:[], 
    get multitask() {
      return localStorage.getItem('multitask') || null;
    },
    get tasksRows() {
      return  parseInt(localStorage.getItem('tasksRows')) || 5 ;
    },
    kanban:false,
    clicks: 0,
    type:{},
    hasPermission:true,
    dialog_adv: false,
    boxID: "",
    search: "",
    reason: "",
    details: false,
    rules: [
      value => !!value || "Required.",
      value => (value && value.length >= 3) || this.$t("mincharacters3"),
      value => (value && value.length <= 200) || this.$t("maxcharacters200")
    ],
    headers: [
      { text: '', value: 'data-table-expand'},
      {
        text: "title",
        sortable: true,
        value: "title",
      },
      { text: "label", value: "label"},
      { text: "requestid", value: "request_id"},
      { text: "assignee", value: "assigned_to"},
      { text: "received", value: "created_at"},
      { text: "due", value: "due_date"},
      { text: "completed", value: "date_completed"},
      { text: "", value: "action", sortable: false},
    ],

    editedItem: {},
    selected_task: [],
    lastTask: {}, 
    delay: 700,
    timer: null,
    filters: {
      owner:JSON.parse(localStorage.getItem("dashboardFilter")),
      title: "",
      status: [],
      requestId: [],
      assignee: [],
      type: [],
      dateRecieved: [],
      dueDate: [],
      closeDate: [],
       label:[]
      
    },
  }),

  computed: {
    ...mapState("request", {
      requests: "requests"
    }),
    ...mapState("task", {
      loading: "loading",storeFilters:"filter"
    }),
    ...mapState({
      users: "users"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
     ...mapState(['user']),
  },

  created() {
        this.filters={};
        Object.assign(this.filters, this.storeFilters);
        //delete this.filters.owner;
        this.filterList=[];
        this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
        this.filterList = this.filterList.filter(el=> el != "");
        this.setDashboardFilterValue()
    },
   watch:{
    filters(val){
        this.filterTasks(val);
    },
   storeFilters: {
     handler(){
        this.filters={};
        Object.assign(this.filters, this.storeFilters);  
        this.filterList=[];
        //delete this.filters.owner;
        this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
        this.filterList = this.filterList.filter(el=> el != "");

     }, deep: true 
     },
     kanban(val){
       if(val){
         this.removeFilter('Opened')
       }
     },
   /*  details(value){
      if( ! value )
      Object.assign(this.editedItem, {});
    }  */
    },
 
  methods: {

    setDashboardFilterValue: function() {
        this.$vuetify.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
        this.storeFilters.owner = JSON.parse(localStorage.getItem("dashboardFilter"));
    },
    setDashboardFilter() {
        this.$vuetify.dashboardFilter = this.storeFilters.owner;
        localStorage.setItem("dashboardFilter", JSON.stringify(this.$vuetify.dashboardFilter));
    },
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0]
    }, 
    resetBoxID(){
      this.boxID='';
    },
    setBoxID(id){
      this.boxID=id;
    },
    getBoxLink(){
      let link='/task/'+this.boxID;
      return link;
    },
    approve(item){
      item.type=3;
      this.updateTask(item).then(() => {
      this.$root.$emit("callAlert", {text: this.$t('taskapproved'), type: "success", alert: true });
      let rs =this.reason!=""?" Reason: '"+this.reason+"'":"";
      let rsfr =this.reason!=""?" Raison: '"+this.reason+"'":"";
      let log_en="Has Approved The Task."+rs
      let log_fr="A approuvé la tâche."+rsfr
      this.postTaskLog({ task_id: item.id,log_en: log_en,log_fr:log_fr, user_id: this.user.id})
       });
    },
    reject(item){
      item.type=4;
      item.status= 0;
      item.date_completed= moment().format("YYYY-MM-DD HH:mm:ss");
      this.updateTask(item).then(() => {
      this.$root.$emit("callAlert", {text: this.$t('taskdenied'), type: "dark", alert: true });
      let rs =this.reason!=""?" Reason: '"+this.reason+"'":"";
      let rsfr =this.reason!=""?" Raison: '"+this.reason+"'":"";
      let log_en="Has denied The Task."+rs
      let log_fr="A refusé la tâche."+rsfr
      this.postTaskLog({ task_id: item.id, log_en: log_en,log_fr:log_fr, user_id: this.user.id})
       });
    },
      removeFilter(item){

          Object.keys(this.filters).forEach((key)=>{
          if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
           this.filters[key]={text:"",value:""};        
          else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
           this.filters[key]="";           

          else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
          this.filters[key]= this.filters[key].filter(a=>a.text!=item);

         else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
          this.filters[key]= this.filters[key].filter(a=>a!=item);
          

          });
            this.filterTasks(this.filters);
        
       }, 
    getRequestCode(id){
      return this.$store.state.request.requests.filter(r=>r.id === id).map(a=>a.reqCode)[0]
    },
    getStatusLookup(column = 'TaskLabel', label){

      return this.configurations
        .filter(filter => filter.LookUpName === column && filter.LookUpKey === JSON.parse(label)[0])
        .map(item => {
          
            return localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          
        });
    },
    getLookup(column) {
      return this.configurations
        .filter(filter => filter.LookUpName === column).map(item => {
          let arr ={};
          arr.text =  localStorage.getItem("language") === "en" ? item.LookUpStringE: item.LookUpStringF;
          arr.value= item.LookUpKey;
          return arr;
        });
    },

    setDetails(details)
    {
      if(!details)
        this.selected_task=[]
      this.details=details
    },
    setcolor(status) {
      if (status === "done") {
        return "success";
      } else if (status === "onHold") {
        return "warning";
      } else if (status === "ongoing") {
        return "primary";
      } else if (status === "todo") {
        return "gray";
      } else if (status === "high") {
        return "error";
      } else if (status === "medium") {
        return "warning";
      } else if (status === "low") {
        return "gray";
      }
      else{
        return "gray";
      }
    },
    showDetails(task) {
      this.selected_task=[];
      this.selected_task.push(task);
      this.clicks++;

      if(this.$checkPermission('Tasks.Update')){
        if (this.clicks > 1 && this.lastTask==task) {
          clearTimeout(this.timer);  
          this.redirectTask(task.id);
          this.clicks = 0;
          this.lastTask={}
        } 
        else{
            this.editedItem = JSON.parse(JSON.stringify(task));
            this.setDetails(true);
            this.lastTask=task;
            this.timer = setTimeout( () => {
                this.clicks = 0;
            }, this.delay);
        }     
      }        
      else{
        this.redirectTask(task.id);
        this.clicks = 0;
        this.lastTask={}
      }
    },
    openInSame(r) {
      this.$router.push(r);
    },

    findTasks() {
      this.dialog_adv = false;
      this.filterTasks(this.filters);
    },
    redirectTask(item){
      this.$router.push({ name: "tasks", params: {id: item} });
    },
    ...mapActions("task", [ "updateTask", "filterTasks","postTaskLog"])
  }
};
</script>
<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.status-chip {
  min-width: 115px;
}

.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -23px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 23px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #14535c;
  color: #14535c;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #14535c;
  }
  50%,
  100% {
    background-color: #ebe6ff77;
  }
}
</style>