<template>
  <div v-if="editedRequest" class="text-right">
    <v-bottom-sheet v-model="localsheet">
      <v-sheet
        class="white pa-4 ma-0"
      >
        <v-row class="pa-0 ma-0"> 
          <v-col cols="12" sm="6" class="text-center text-sm-left ma-auto px-0">
            {{ $t('Closing Information') }} 
          </v-col>
          <v-col cols="12" sm="6" class="text-center text-sm-right ma-auto  px-0">
            <v-btn class="pa-auto ma-auto mr-2" @click="setSheet(false)" depressed>{{$t("cancel")}}</v-btn>
            <v-btn class="pa-auto ma-auto custom-color-accent" @click="closeRequest" depressed>{{$t("close")}}</v-btn>
          </v-col>
        </v-row>
       
        <div class="customlightgray pa-3 ma-0 my-border">
          <v-row class="pa-0 ma-0 sheet">
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('Date Closed') }}:</span>
                  </v-col>
                  <v-col cols="6" class="pa-auto ma-0 text--secondary text-capitalize">
                    <label>
                      {{ getToday() }}
                    </label>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('releaseflag') }}: </span>
                  </v-col>
                  <v-col v-if="update_release_flag&& $checkPermission('Requests.Update')"  cols="12" md="6" class="pa-0 ma-0">
                    <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_release_flag = false,modify=false"
                        @click:append-outer="save('update_release_flag')" clearable clear-icon="mdi-delete" v-model="editedRequest.releaseFlag"
                        :items="getLookup( 'ReleaseFlg' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                    <v-col cols="6" v-else class="pa-auto ma-0"><v-tooltip right>
                        <template v-slot:activator="{ on }">
                              <label v-if="editedRequest.releaseFlag" v-on="on" @click="modify = true , update_release_flag=true" class="clickable ">
                                {{ getlookupvalue("ReleaseFlg", editedRequest.releaseFlag) }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_release_flag=true" class="clickable ">
                                ------
                              </label>
                        </template>
                        <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_release_flag=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('accessmethod') }}: </span>
                  </v-col>
                  <v-col v-if="update_access_method&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_access_method = false,modify=false"
                        @click:append-outer="save('update_access_method')" clearable clear-icon="mdi-delete" v-model="editedRequest.accessMethod"
                        :items="getLookup( 'AccMethod' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                    <v-col cols="6" v-else class="pa-auto ma-0">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <label v-if="editedRequest.accessMethod" v-on="on" @click="modify = true , update_access_method=true" class="clickable text-capitalize">
                              {{ getlookupvalue("AccMethod", editedRequest.accessMethod) }}
                            </label>
                            <label v-else v-on="on" @click="modify = true , update_access_method=true" class="clickable ">
                              ------
                            </label>
                        </template>
                        <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_access_method=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('requestdisposition') }}: </span>
                  </v-col>
                  <v-col v-if="update_request_disposition&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_request_disposition = false,modify=false"
                        @click:append-outer="save('update_request_disposition')" clearable clear-icon="mdi-delete" v-model="editedRequest.RequestDisposition" 
                        :items="getLookup( 'Dispose' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                  <v-col v-else cols="6" class="pa-auto ma-0">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                              <label v-if="editedRequest.RequestDisposition" v-on="on" @click="modify = true , update_request_disposition=true" class="clickable ">
                                {{ getlookupvalue("Dispose", editedRequest.RequestDisposition) }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_request_disposition=true" class="clickable ">
                                ------
                              </label>
                        </template>
                        <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_request_disposition=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('latereason') }}: </span>
                  </v-col>
                  <v-col v-if="update_late_reason&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-autocomplete append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_late_reason = false,modify=false"
                        @click:append-outer="save('update_late_reason')" clearable clear-icon="mdi-delete" v-model="editedRequest.LateReason" 
                        :items="getLookup( 'LateReason' )" class="ma-0 pa-0 pr-2 white my-border" flat solo dense hide-details></v-autocomplete>
                  </v-col>
                    <v-col v-else cols="6" class="pa-auto ma-0">
                        <v-tooltip right>
                        <template v-slot:activator="{ on }">
                              <label v-if="editedRequest.LateReason" v-on="on" @click="modify = true , update_late_reason=true" class="clickable ">
                                {{ getlookupvalue("LateReason", editedRequest.LateReason) }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_late_reason=true" class="clickable ">
                                ------
                              </label>
                        </template>
                        <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_late_reason=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="ma-0 pa-1 task-column">
              <div class="d-flex text-left pa-0 h-100 ma-0">
                <v-row class="pa-0  mx-3 my-auto">
                  <v-col cols="6" class="pa-auto ma-0">
                    <span class="d-flex pr-2 text--disabled text-capitalize">{{ $t('otherlatereason') }}: </span>
                  </v-col>
                  <v-col v-if="update_late_reason_other&& $checkPermission('Requests.Update')" cols="12" md="6" class="pa-0 ma-0">
                    <v-textarea clearable clear-icon="mdi-delete" append-icon="mdi-close" append-outer-icon="mdi-check" @click:append="update_late_reason_other = false,modify=false"
                        @click:append-outer="save('update_late_reason_other')" v-model="editedRequest.LateReasonOther" rows="1" auto-grow hide-details dense class="ma-0 pa-0 pr-2 white my-border" flat solo></v-textarea>
                  </v-col>
                    <v-col v-else cols="6" class="pa-auto ma-0"><v-tooltip right>
                        <template v-slot:activator="{ on }">
                              <label v-if="editedRequest.LateReasonOther" v-on="on" @click="modify = true , update_late_reason_other=true" class="clickable ">
                                {{ editedRequest.LateReasonOther }}
                              </label>
                              <label v-else v-on="on" @click="modify = true , update_late_reason_other=true" class="clickable ">
                                ------
                              </label>
                        </template>
                        <v-btn v-if="$checkPermission('Requests.Update')" @click="modify = true , update_late_reason_other=true" icon color="teal">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-tooltip>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>  
              </div>
            </v-col>


          </v-row>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex';
import moment from "moment";

  export default {
    props: ["request","sheet"],
      data() {
        return {
          editedRequest:{},
          localsheet:false,
          modify: false,
          update_release_flag:false,
          update_access_method:false,
          update_request_disposition:false,
          update_late_reason:false,
          update_late_reason_other:false,
        }
      },
      created(){
        Object.assign(this.editedRequest, this.request);
        this.localsheet=this.sheet;
        this.request.request_type?this.editedRequest.request_type = this.request.request_type:'';
      },

     watch:{
       sheet(val){
         this.localsheet=val;
       },
       localsheet(val){
          this.setSheet(val)
       },
       request(val){
          Object.assign(this.editedRequest,val);
          val.request_type?this.editedRequest.request_type = val.request_type:'';
       }
      },
      computed: {
        ...mapState("configuration", {
          configurations: "configurations"
        }),
      },
    methods:{ 
      getToday(){
        return  moment().format("YYYY-MM-DD");
      },
       getlookupvalue(lookupname, val) {
          return this.configurations.filter(filter => filter.LookUpName === lookupname && filter.LookUpKey == val).map(item => {
            let status = "";
            status = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
            return status;
          })[0]
        },
        getLookup(column) {
          return this.configurations.filter(filter => filter.LookUpName === column).map(item => {
            let arr = [];
            arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
            arr['value'] = item.LookUpKey;
            return arr;
          })
        },
        setSheet(sheet){
          this.$emit('setSheet',sheet);
        },
        setEditedClosing(){
          this.editedRequest.RequestStatus='Complete';
          this.editedRequest.DateOfClosure= this.getToday();
        },
        closeRequest(){
         this.setEditedClosing();
          this.updateRequest(this.editedRequest).then(() => {
            this.save()
            this.setSheet(false);
            //this.$root.$emit('reloadPage');
            let item = {text:this.$t("requestclosedalert"), type:"dark", alert:true};
            this.$root.$emit("callAlert", item);
            this.retriveRequests();
          })
        },
         save(obj) {
          this.modify = false;
          switch(obj){
            case 'update_release_flag':
              this.update_release_flag = false;
              break;
            case 'update_access_method':
              this.update_access_method = false;
              break;
            case 'update_request_disposition':
              this.update_request_disposition = false;
              break;
            case 'update_late_reason':
              this.update_late_reason = false;
              break;
            case 'update_late_reason_other':
              this.update_late_reason_other = false;
              break;
          }
        },
        ...mapActions("request", ["updateRequest",'retriveRequests']),

    }
  }
</script>
<style lang="scss" scoped>
.v-tooltip__content {

  background: rgba(0, 0, 0, 0) !important;
  pointer-events: auto !important;
}

</style>