<template>
    <div class="customlightgray py-3 my-border">
        <v-row class="pa-0 ma-0 text-left">
            <v-col cols="12">
                <div class="pa-3 ma-auto font-weight-bold clickable hoverable text-left" @click="goback" v-if="task.parent_id" ><v-icon class="pa-0 ma-0">mdi-dots-horizontal</v-icon></div>
                <v-treeview
                    v-model="tree"
                    :items="getItems()"
                    item-key="id"
                    color="dark"
                    :open-all="isopen"
                    open-on-click
                    >
                    <template v-slot:prepend="{ item }" >
                        <v-icon :class="allTask.filter(i=>i.id==item.id)[0].status==0?'success--text':''">{{item.id}}
                            mdi-check-circle
                        </v-icon>
                    </template>
                    <template slot="label" slot-scope="props" >
                      <v-row class="pa-0 ma-0" @click="goto(props.item)">
                        <v-col cols="6" md="8" class="my-auto pa-0 text-wrap"><span>{{ props.item.name }}</span></v-col>
                        <v-col cols="6" md="4" class="ma-0 pa-0 text-right justify-end">{{props.item.children.length}}
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="props.item.children" :disabled="task.type==2" v-on='on' v-bind="attrs" @click.stop="openDetails(props.item.id)" class="mx-auto pa-auto text-center" icon>
                              <v-icon class="ma-0 pa-0">mdi-plus-box</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t("Add Task")}}</span>
                        </v-tooltip>
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="props.item.id" v-on='on' v-bind="attrs" @click.stop="goto(props.item)" class="mx-auto pa-auto text-center" icon>
                              <v-icon class="ma-0 pa-0">mdi-location-enter</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t("Task Details")}}</span>
                        </v-tooltip>
                        </v-col>
                      </v-row>                        
                    </template>
                </v-treeview>
            </v-col>


        </v-row>
     <!-- Edit task section -->
    <v-scroll-x-transition >
      <subtasksidebar v-show="details" @setDetails="setDetails" :details="details" :task="editedItem"></subtasksidebar>
    </v-scroll-x-transition>
    <!-- /Edit task section -->
    </div>
</template>
<script>
import { mapState } from 'vuex';
import subtasksidebar from "@/components/task/SubTaskSidebar";

export default {
    props:['current','isopen','task'],
  components:{
    subtasksidebar
  },
  data() {
    return {
      active: [],
      title:'',
      details:false,
      pid:null,
      editedItem:{},
      files: {
        parent: 'mdi-checkbook',
      },
      tree: [],
    };
  },
  computed:{
      ...mapState('task',{
        allTask: "tasks"
      })
  },
  created(){
    this.active = [this.task.id];
  },
  watch:{
    task(val){
      this.active=[];
      this.active = [val.id];
    },
  },
  methods:{
    setDetails(details)
    {
      this.details=details;
    },
    getItems(){
        let items = [{ name: this.task.title, id:this.task.id,children: []}];
        this.allTask.filter(i => i.parent_id === this.task.id ).length ? this.recursiveItem(items) : "";
        return items;
      },
      recursiveItem(item){
        //let children = [];
        this.allTask.filter(i => i.parent_id === item[0].id ).map(j => {
          let items = [{ name: j.title,id:j.id, children: []}];
          item[0].children.push(items[0]);
          this.allTask.filter(i => i.parent_id === j.id ).length ? this.recursiveItem(items) : "";
        })
      },
    openDetails(id) {
      this.editedItem={};
      Object.assign(this.editedItem,{title:'',label:'',due_date:'',request_id:'',type:'',description:'',parent_id:id});
      this.setDetails(true);
    },
    goto(item){
      this.$emit('setTask', item.id)
    },
    goback() {
      if( this.task.parent_id){
        this.$emit('setTask', this.task.parent_id)
      }
    },
  }
}
</script>
<style scoped>

.text-wrap{
    word-break: break-word;
}
</style>