<template >

  <v-card class="ma-0 pa-0 pt-2 elevation-0 white" style="max-height:100%">
    <div class=" my-border">
        <v-card-title class="py-0 my-0 customlightgray">
              <v-row class="ma-0 pa-0 py-2 justify-center text-left">
                <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
                  <div class="font-weight-medium text-left text--secondary">  
                      <div class="ma-auto pa-auto">
                        <span class="ma-0 pa-1">{{$t("requests")}}</span>
                        <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{getRequetsByStatus.length}}
                        </span>
                      </div>
                  </div>
                </v-col>
                <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
                  cols="12" 
                  sm="12"
                  md="4"
                  lg="4">  
                      <v-text-field
                        v-model="search"
                        class="ma-0 pa-0"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        dense 
                        outlined
                        clearable
                        hide-details
                      >
                       <template v-slot:prepend-inner>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                            </template>
                            <span>{{$t("advanced")}}</span>
                          </v-tooltip>
                        </template>
                      </v-text-field> 
                </v-col>
                <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3">
                    <requestnew v-if="$checkPermission('Requests.Create')"></requestnew>
                </v-col>
                
            </v-row>
            
        </v-card-title>

       <!--  dialog delete -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title>{{$t("delete")}}</v-card-title>
            <v-card-text>{{$t("Are you sure you want to delete this item?")}}</v-card-text>
            <v-card-actions>
              <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
              <v-btn class="custom-color-accent-text" text @click="deletehistory()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
       <!--  //dialog delete -->
       <!--  dialog advanced search -->
          <v-dialog
            v-model="dialog_adv"
            max-width="800px">
              <v-card class="customoffwhite">
                  <v-card-title class="justify-center ma-0 customlightgray">
                    <span class="font-weight-light">{{$t("advanced")}}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="mt-4">
                    <v-container class="pa-0 ma-0" v-if="dialog_save">
                       <v-text-field v-model="history_title" :rules="[rules.required]" outlined dense :label="$t('title')" :placeholder="$t('title')"></v-text-field>
                    </v-container>
                    <v-container class="pa-0 ma-0" v-else>
                      <v-autocomplete
                          :items="filters.owner.value=='2'?searchHistory:searchHistory.filter(item=>item.user_id==user.id)"
                          :label="$t('searchhistory')"
                          :placeholder="$t('searchhistory')"
                          item-value="id"
                          outlined
                          hide-details
                          clearable
                          @click:clear="clear()"
                          dense
                          v-model="selectedHistory"
                          v-on:change="populatefields()"
                          return-object
                        >
                          <template slot="selection" slot-scope="data">
                              <span class="ma-auto">{{ data.item.title}}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-row class="pa-0 ma-0">
                                <span class="ma-auto">{{ data.item.title}}</span>
                                <v-spacer></v-spacer>
                                <v-btn class="justify-right" v-if="data.item.user_id==user.id" @click.stop="deleteSearch(data.item)" icon><v-icon>mdi-delete</v-icon></v-btn>
                                
                            </v-row>
                          </template>
                        </v-autocomplete>
                        
                      <v-toolbar-title class="mb-2 mt-3">{{$t('filter')}}</v-toolbar-title>

                          <v-autocomplete
                          :label="$t('requestid')"
                          :placeholder="$t('requestid')"
                          outlined
                          :items="requests.map(request=>{return {value:request.reqCode,text:request.reqCode}})"
                          clearable
                          dense
                          v-model="filters.reqid"
                           return-object
                        ></v-autocomplete>

                          <v-autocomplete
                          :items="getLookup('ReqStatus')"
                          :label="$t('requeststatus')"
                          :placeholder="$t('requeststatus')"
                          outlined
                          dense
                          v-model="filters.status"
                          multiple
                          clearable
                          return-object
                        ></v-autocomplete>

                        <v-autocomplete
                          :items="getLookup('Source')"
                          :label="$t('source')"
                          :placeholder="$t('source')"
                          outlined
                          dense
                          clearable
                          return-object
                          v-model="filters.reqsource"
                        ></v-autocomplete>


                        <v-autocomplete
                          :items="getLookup('AddType')"
                          :label="$t('requestortype')"
                          outlined
                          :placeholder="$t('requestortype')"
                          dense
                          clearable
                          return-object
                          v-model="filters.requester"
                        ></v-autocomplete>

                          <v-autocomplete
                          :items="getLookup('ReleaseFlg')"
                          outlined
                          :label="$t('releaseflag')"
                          :placeholder="$t('releaseflag')"
                          dense
                          clearable
                          return-object
                          v-model="filters.releaseFlag"
                        ></v-autocomplete>

                        <v-autocomplete
                          :items="getLookup('Event')"
                          :label="$t('eventtype')"
                          :placeholder="$t('eventtype')"
                          outlined
                          dense
                          return-object
                          v-model="filters.eventype"
                          clearable
                          multiple
                        ></v-autocomplete>
                          <v-autocomplete
                          :items="getLookup('ATIP')"
                          :label="$t('clausesapplied')"
                          :placeholder="$t('clausesapplied')"
                          outlined
                          dense
                          clearable
                          v-model="filters.clause"
                          return-object
                          ></v-autocomplete>
                        <v-menu
                          class="ma-0"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                v-model="filters.receiveddaterange"
                                multiple
                                chips
                                dense
                                clearable
                                outlined
                                small-chips
                                :label="$t('receiveddaterange')"        
                                :placeholder="$t('receiveddaterange')"        
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              >
                                <template v-slot:selection="data">
                                  <v-chip :input-value="data.selected"
                                          class="custom-color-accent" small>
                                      <strong>{{ data.item }}</strong>
                                  </v-chip>
                              </template>
                              </v-combobox>
                            </template>
                            <v-date-picker
                              range
                              :max="nowDate"
                              v-model="filters.receiveddaterange"
                            >
                              <v-autocomplete :items="fiscalDates" v-model="selectedReceivedFiscalDate" :label="$t('select fiscal year')" :placeholder="$t('select fiscal year')" clearable dense outlined hide-details></v-autocomplete>                                 
                            </v-date-picker>
                        </v-menu>
                        <v-menu
                          class="ma-0"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                              <v-combobox
                              v-model="filters.duedaterange"
                              multiple
                              clearable
                              chips
                              small-chips
                              dense
                              outlined
                              :label="$t('duedaterange')"        
                              :placeholder="$t('duedaterange')"        
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:selection="data">
                                <v-chip :input-value="data.selected"
                                        class="custom-color-accent" small>
                                    <strong>{{ data.item }}</strong>
                                </v-chip>
                            </template>
                            </v-combobox>
                          </template>
                          <v-date-picker
                            range
                            v-model="filters.duedaterange"
                          >           
                            <v-autocomplete :items="fiscalDates" v-model="selectedDueFiscalDate" :label="$t('select fiscal year')" :placeholder="$t('select fiscal year')" clearable dense outlined hide-details></v-autocomplete>                                 
                          </v-date-picker>     
                        </v-menu>
                        <v-menu
                          class="ma-0"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                              <v-combobox
                              v-model="filters.closeddaterange"
                              multiple
                              clearable
                              chips
                              dense
                              small-chips
                              outlined
                              :label="$t('closeddaterange')"        
                              :placeholder="$t('closeddaterange')"        
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template v-slot:selection="data">
                                <v-chip :input-value="data.selected"
                                        class="custom-color-accent" small>
                                    <strong>{{ data.item }}</strong>
                                </v-chip>
                            </template>
                            </v-combobox>
                          </template>
                          <v-date-picker
                            range
                            v-model="filters.closeddaterange"
                          >       
                            <v-autocomplete :items="fiscalDates" v-model="selectedClosedFiscalDate" :label="$t('select fiscal year')" :placeholder="$t('select fiscal year')" clearable dense outlined hide-details></v-autocomplete>                                 
                          </v-date-picker>
                        </v-menu>
                        <v-textarea
                          v-model="filters.original"                                            
                          counter
                          auto-grow
                          row-height="10"
                          :label="$t('originaltext')"                                            
                          :placeholder="$t('originaltext')"
                          dense
                          outlined                                            
                        ></v-textarea>
                    </v-container>
                  </v-card-text>

                  <v-card-actions  v-if="dialog_save">
                    <v-spacer></v-spacer>
                    <v-btn class="custom-color-accent-text" text @click="dialog_save=false">{{$t('goback')}}</v-btn>
                    <v-btn class="custom-color-accent-text" text @click="savehistory()">{{$t('save')}}</v-btn>
                  </v-card-actions>
                  <v-card-actions  v-else>
                    <v-spacer></v-spacer>
                    <v-btn class="custom-color-accent-text" text @click="clear()">{{$t('clear')}}</v-btn>
                    <v-btn class="custom-color-accent-text" text @click="saveSearch()">{{$t('save')}}</v-btn>
                    <v-btn class="custom-color-accent-text" text @click="find()">{{$t('search')}}</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
       <!--  /dialog advanced search -->
        <v-divider  v-show="filterList.length>0"></v-divider>       
        <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
          <v-col class="pa-0 ma-0" cols="12">
            <v-scroll-x-transition group hide-on-leave>
              <v-tooltip v-for="(f, i) in filterList" :key="i" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip  v-on='on' v-if="i>0" v-bind="attrs"
                    class="ma-1"
                    color="custom-color-accent"
                    close
                    small
                    close-icon="mdi-delete"
                    @click:close="removeFilter(f)" >
                      <span class="text-capitalize">{{f}}</span>
                  </v-chip> 
                  <v-chip v-else
                    class="ma-1"
                    color="custom-color-accent"
                    small >
                      <span class="text-capitalize">{{f}}</span>
                  </v-chip>
                </template>
                <span>{{$t("removefilter")}}</span>
              </v-tooltip>
            </v-scroll-x-transition>
          </v-col>
        </v-row> 
         
        <v-divider></v-divider>
          <div v-if="boxID!=''" @mouseleave="resetBoxID" class="preview-box">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pa-0 ma-0 text-right">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn style="margin-bottom :-60px !important;margin-right:10px" icon v-on='on' v-bind="attrs" @click.stop link :href="getBoxLink()" target="_blank">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t("openinnew")}}</span>
                  </v-tooltip>
              </v-col>
              <v-col cols="12" class="pa-0 ma-0"><iframe :src="getBoxLink()"  class="preview"></iframe></v-col>
            </v-row>
          </div>
          <v-data-table :mobile-breakpoint="1081" 
              :headers="headers"
              :items="reqs"    
              v-model="selected"
              :items-per-page="requestsRows"
              :search="search"
              class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
              :loading-text="$t('Loading')"
              :loading="loading"
              :footer-props="{
            'items-per-page-text':$t('rowsperpage')}"
              item-key="id"
              sort-by="id" 
              @click:row="viewRequest"
              :sort-desc="true">
              <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ }">
                {{$t(header.text)}}
              </template>
              <template v-slot:[`item.reqCode`]="{item}">
                <span :class="multitask=='true'?'preview-link custom-color-accent-text':multitask" @mouseover="multitask=='true'?setBoxID(item.id):''">{{ item.reqCode }}</span>   
              </template>
              <template v-slot:[`item.DateReceived`]="{item}">{{ item.DateReceived | formatDate }}</template>
              <template v-slot:[`item.DateDue`]="{item}">
              {{ calculateDueDate(item) | formatDate }}
              </template>
              <template v-slot:[`item.DateOfClosure`]="{item}">{{ item.DateOfClosure | formatDate }}</template>
              <template v-slot:[`item.ATIPOfficerId`]="{item}"><span class="text-capitalize">{{ getUser(item.ATIPOfficerId) }}</span></template>
              <template v-slot:[`item.address`]="{item}"><span class="text-capitalize">{{ item.address.FirstName+' '+item.address.LastName }}</span></template>
              <template v-slot:[`item.statuslang`]="{ item }">
                  <v-chip 
                    class="pa-0 ma-0 mx-1 justify-center status-chip"
                    small
                    :color='setcolor(item.statuslang)'
                  >
                      {{ item.statuslang }}
                  </v-chip>
              </template>
              <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
                <v-row class="ma-0 pa-0 text-right">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn :disabled="item.DateOfClosure!=null || !$checkPermission('Requests.Close')" icon @click.stop="closeRequest(item)">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs">mdi-clipboard-check-outline</v-icon>
                        </template>
                        <span>{{$t("closereq")}}</span>
                      </v-tooltip>
                    </v-btn>
                    
                  </v-col>
                </v-row>
              </template>
          </v-data-table>
    </div>
    <requestbottomsheet v-show="sheet" v-if="closeReq!={}" :request="closeReq" :sheet="sheet" @setSheet="setSheet"></requestbottomsheet>
  </v-card>
</template>
<script>
import {mapState, mapActions, mapGetters} from "vuex";
import requestnew from "@/components/request/RequestNew";
import moment from 'moment';
import requestbottomsheet from '@/components/request/RequestBottomSheet'

  export default {
   
    components: {
      requestnew,
      requestbottomsheet
    },
    data(){
    return {
      boxID: '',
      get multitask() {
        return localStorage.getItem('multitask') || null;
      },
      get requestsRows() {
        return  parseInt(localStorage.getItem('requestsRows')) || 10;
      },
      search: '',
      nowDate: new moment().toISOString(),
      fiscalDates:[],
      reqs:[],
      filterList:[],
      sheet: false,
      closeReq:{},
      dialog_adv: false,
      dialog_save: false,
      dialogDelete: false,
      selected: [],
      rules: {
          required: value => !!value || this.$t('required'),
      },
      headers: [
        {
          text: 'requestid',
          align: 'start',
          sortable: true,
          value: 'reqCode',
        },
        { text: 'status', value: 'statuslang' },
        { text: 'requestor', value: 'address' },
        { text: 'assignee', value: 'ATIPOfficerId' },
        { text: 'received', value: 'DateReceived' },
        { text: 'due', value: 'DateDue' },
        { text: 'closed', value: 'DateOfClosure' },
        //{ text: 'Days', value: 'days' },
        { text: '', value: 'action', sortable: false },
      ],
      history_title:"",
      selectedReceivedFiscalDate:null,
      selectedDueFiscalDate:null,
      selectedClosedFiscalDate:null,
      selectedHistory:{},
      deletedItem:{},
      filters:{
           owner:JSON.parse(localStorage.getItem("dashboardFilter")),
           status:[{text:"active",value:"active"}],
           clause:{value:"",text:""},
           reqid:{value:"",text:""},
           requester:{value:"",text:""},
           eventype:[],
           original:"",
           releaseFlag:{value:"",text:""},
           reqsource:{value:"",text:""},
           receiveddaterange:[],
           duedaterange:[],  
           closeddaterange:[],
           },
     }
     },

    async created () {
        await this.clearRequestFilters();
        await this.retrieveDocuments();
        await this.retrieveLayerBoxes();
        await this.retrieveLayers();
        this.filters={};
        Object.assign(this.filters, this.storeFilters); 
        //delete this.filters.owner;
        this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a);
        this.filterList = this.filterList.filter(el=> el != "");
        this.reqs=[]
        Object.assign(this.reqs, this.requests);  
        this.getRequetsByStatus();
        this.setDashboardFilterValue();
        let thisyear=moment().year()
        let thismonth=moment().month()
        for(let i=thismonth>2?thisyear+1:thisyear;i>2000;i--){
          this.fiscalDates.push(i-1+'-'+i)
        }
    },
    watch:{
        storeFilters: {
          handler(){
              this.filters={};
              Object.assign(this.filters, this.storeFilters); 
              this.filterList=[];
              //delete this.filters.owner;
              this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a);
              this.filterList = this.filterList.filter(el=> el != "");
              this.reqs=[]
              Object.assign(this.reqs, this.requests);  
              this.getRequetsByStatus();
          }, deep: true 
        },
        requests:{
           handler(){
             this.reqs=[]
              Object.assign(this.reqs, this.requests);  
              this.getRequetsByStatus()
           },deep:true
         },
        selectedReceivedFiscalDate(val){
          if(val){
            let range = val.split('-');
            let startd=range[0]+'-04-01';
            let endd=range[1]+'-03-31';
            this.filters.receiveddaterange=[startd,endd]
          }
          else{
            this.filters.receiveddaterange=[];
          }
        },
        selectedDueFiscalDate(val){
          if(val){
            let range = val.split('-');
            let startd=range[0]+'-04-01';
            let endd=range[1]+'-03-31';
            this.filters.duedaterange=[startd,endd]
          }
          else{
            this.filters.duedaterange=[];
          }
        },
        selectedClosedFiscalDate(val){
          if(val){
            let range = val.split('-');
            let startd=range[0]+'-04-01';
            let endd=range[1]+'-03-31';
            this.filters.closeddaterange=[startd,endd]
          }
          else{
            this.filters.closeddaterange=[];
          }
        },
    },

    computed: {
      ...mapGetters({
        getTypes:"getTypes"
      }),
      ...mapGetters('documents',{
        documents:"getDocuments",layers:"getLayers",boxes:"getLayerBoxes"
      }),
      ...mapState('request',{
        'requests':'requests', 'loading':'loading','storeFilters':'filters','searchHistory':'searchHistory'
      }),
      ...mapState('configuration',{
        'configurations':'configurations'
      }),
      ...mapState(['types']),
      ...mapState({
        users: "users"
      }), ...mapState({
      user: "user"
    })
    },
    methods: {
    setDashboardFilterValue: function() {
        this.$vuetify.dashboardFilter = JSON.parse(localStorage.getItem("dashboardFilter"));
        this.storeFilters.owner = JSON.parse(localStorage.getItem("dashboardFilter"));
    },

    populatefields(){
      if(this.selectedHistory)
      this.filters=JSON.parse(this.selectedHistory.searchstring);
    },
    getUser(id){
      return this.users.filter(i => i.id === id ).map(j=>j.first_name+' '+j.last_name)[0]
    },
    resetBoxID(){
      this.boxID='';
    },
    setBoxID(id){
      this.boxID=id;
    },
    getBoxLink(){
      let link='/request/over/'+this.boxID;
      return link;
    },
    getRequetsByStatus() {
      if(this.storeFilters.owner.value=='0'){
        this.reqs = this.reqs.filter(a => a.ATIPOfficerId == JSON.parse(localStorage.getItem('user')).id);
      }
      for (let request of this.reqs) {
          request.statuslang = localStorage.getItem('language') === 'en' ?
                                this.configurations.filter(i=>i.LookUpKey === request.RequestStatus)[0]?.LookUpStringE :
                                this.configurations.filter(i=>i.LookUpKey === request.RequestStatus)[0]?.LookUpStringF;
      }
      if (this.filters.status.length > 0){
        this.reqs = this.reqs.filter(request => this.filters.status.some(stat => {
          if(stat.value=="active"){
            return (request.DateOfClosure == null)   
          }
          else if(stat.value=="late"){
            return (moment(this.calculateDueDate(request)).diff(moment(), "days") < 0 && request.DateOfClosure == null)
          }
          else if(stat.value=="Hold"){
            return request.RequestStatus==stat.value
          }
        }));
      }

      if (this.filters.reqid.value)
        this.reqs = this.reqs.filter(a => a.reqCode == this.filters.reqid.value);


      if (this.filters.requester.value)
        this.reqs = this.reqs.filter(a => a.address.AddressType == this.filters.requester.value);
      

      if (this.filters.releaseFlag.value)
        this.reqs = this.reqs.filter(a => a.releaseFlag == this.filters.releaseFlag.value);

      if (this.filters.eventype.length > 0)
        this.reqs = this.reqs.filter(request => this.filters.eventype.every(event => request.events.map(ev => ev.event_type).includes(event.value)));

      if (this.filters.original)
        this.reqs = this.reqs.filter(a => a.RequestText && a.RequestText.toUpperCase().indexOf(this.filters.original.toUpperCase()) !== -1);

      if (this.filters.clause.value) 
        this.reqs = this.reqs.filter(request =>this.documents.filter(doc=>doc.request_id==request.id).some(document=> this.layers.filter(i=>i.id==document.id).some(layer=>this.boxes.filter(j=>j.layer_id==layer.id).some(box=>box.clausesUsed.includes(this.filters.clause.value)))));


      if (this.filters.reqsource.value) {
        this.reqs = this.reqs.filter(a => a.address.Source == this.filters.reqsource.value)}

      
      if(this.filters.receiveddaterange.length==1)
        this.reqs= this.reqs.filter(item =>new Date(moment(item.DateReceived).format("YYYY-MM-DD")).getTime() == new Date(moment(this.filters.receiveddaterange[0]).format("YYYY-MM-DD")).getTime());
       
      if(this.filters.receiveddaterange.length==2)
        this.reqs= this.reqs.filter(item =>
        new Date(moment(item.DateReceived).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.filters.receiveddaterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.filters.receiveddaterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateReceived).format("YYYY-MM-DD")).getTime());
     

      if(this.filters.duedaterange.length==1)
        this.reqs= this.reqs.filter(request =>new Date(moment(this.calculateDueDate(request)).format("YYYY-MM-DD")).getTime() == new Date(moment(this.filters.duedaterange[0]).format("YYYY-MM-DD")).getTime());
        
      if(this.filters.duedaterange.length==2)
        this.reqs= this.reqs.filter(request =>
        new Date(moment(this.calculateDueDate(request)).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.filters.duedaterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.filters.duedaterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.calculateDueDate(request)).format("YYYY-MM-DD")).getTime());


      if(this.filters.closeddaterange.length==1)
        this.reqs= this.reqs.filter(item =>new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() == new Date(moment(this.filters.closeddaterange[0]).format("YYYY-MM-DD")).getTime());
        
      if(this.filters.closeddaterange.length==2)
        this.reqs= this.reqs.filter(item =>
        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.filters.closeddaterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.filters.closeddaterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime());
    
  },
    closeRequest(item){
     this.closeReq=item;
      this.setSheet(true);
    },
    setSheet(sheet){
      this.sheet=sheet;
    },
    getLookup( column ){
      let tab= this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = {};
        arr.text= localStorage.getItem('language') === 'en'  ? item.LookUpStringE : item.LookUpStringF;
        arr.value = item.LookUpKey;
         return  arr;
      });
      column=="ReqStatus"? tab.unshift({text:this.$t("active"),value:"active"},{text:this.$t("late"),value:"late"}):"";
      return tab;
    },
    getLookupForDueDate(column) {
      return this.configurations.filter(filter => filter.LookUpName === column).map(item => {
        let arr = [];
        arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return arr;
      })
    },
    find(){
      this.searchRequest(this.filters);
      this.dialog_adv = false;
    },
    saveSearch(){
      this.dialog_save=true;
    },
    savehistory(){
      let history={user_id:this.user.id,title:this.history_title,searchstring:JSON.stringify(this.filters)}
      this.addSearchHistory(history).then(()=>{
        let color="success";
        let alert="itemAddedAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        this.dialog_save=false;
      });
    },
    deleteSearch(item){
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    deletehistory(){
        this.dialogDelete=false;
      this.deleteSearchHistory(this.deletedItem.id).then(()=>{
        let color="dark";
        let alert="itemRemovedAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      });
    },
    viewRequest(req){
      this.$router.push({name: 'requests',params: { id: req.id }})
    },

    setcolor(status){
      if(status==='Request Closed'){
        return 'error';
      }
      else if (status==='On Hold'){
        return 'warning';
      }
    
      else if(status==='New'){
        return 'custom-color-accent'
      }
      else{
          return "gray";
      }
    },

    setFiscalYear(year,datetype){
  
        
        let fiscalYear=[];

          if(year=='current'){

          if(Date.parse(moment())>Date.parse(moment().month('March').endOf('month').format("YYYY-MM-DD")))
            fiscalYear= [moment().month('April').startOf('month').format("YYYY-MM-DD"), moment().add(1,'year').month('March').endOf('month').format("YYYY-MM-DD")];    
          else        
            fiscalYear= [moment().subtract(1,'year').month('April').startOf('month').format("YYYY-MM-DD"),moment().month('March').endOf('month').format("YYYY-MM-DD")];
        }
        else
        {
              
          if(Date.parse(moment())>Date.parse(moment().month('March').endOf('month').format("YYYY-MM-DD")))
            fiscalYear= [moment().subtract(1,'year').month('April').startOf('month').format("YYYY-MM-DD"),moment().month('March').endOf('month').format("YYYY-MM-DD")];   
          else        
            fiscalYear= [moment().subtract(2,'year').month('April').startOf('month').format("YYYY-MM-DD"), moment().subtract(1,'year').month('March').endOf('month').format("YYYY-MM-DD")];
        }
        

        if(datetype=="recieved")
          this.filters.receiveddaterange=fiscalYear;  
        if(datetype=="due")
        this.filters.duedaterange=fiscalYear;   
        if(datetype=="closed")
        this.filters.closeddaterange=fiscalYear;           
    },
    removeFilter(item){

      Object.keys(this.filters).forEach((key)=>{
        if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
          this.filters[key]={text:"",value:""};       

        else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
          this.filters[key]="";           

        else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
        this.filters[key]= this.filters[key].filter(a=>a.text!=item);

        else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
        this.filters[key]= this.filters[key].filter(a=>a!=item);
        

        });
          this.searchRequest(this.filters);
    },
    clear(){
      this.selectedHistory={};
      this.filters={
           owner:{ value:'2', text:localStorage.getItem('language') === 'en'?'All':'Tous',icon:'mdi-domain'},
           status:[],
           clause:{value:"",text:""},
           reqid:{value:"",text:""},
           requester:{value:"",text:""},
           eventype:[],
           original:"",
           releaseFlag:{value:"",text:""},
           reqsource:{value:"",text:""},
           receiveddaterange:[],
           duedaterange:[],  
           closeddaterange:[],
           }
      this.clearRequestFilters()
      },  
    
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      //req.DateDue=date;
      return date;
    },

        ...mapActions('request', ['searchRequest','addSearchHistory','deleteSearchHistory','clearRequestFilters']),
        ...mapActions("documents", ["retrieveDocuments","retrieveLayerBoxes","retrieveLayers"]),
    },
  }
</script>
<style scoped>
.fix{
  position: relative;
}
.status-chip{
  min-width: 180px;
}
</style>