<template>
  <div class="">
    <v-autocomplete 
      v-model="activity"
      :items="getLookup('Event')"
      :placeholder="$t('Activity')"
      class="white pa-auto ma-auto"
      clearable
      dense
      outlined
      hide-details
      @change="setTitle()"
    ></v-autocomplete>
    <v-scroll-x-transition>
      <fieldset v-show="activity" class="text-center mt-2 my-border">
        <legend class="text--disabled ma-auto px-2">{{getTitle()[0]}}</legend>
        <div class="pa-2 ma-0">
          <v-form  v-on:submit.prevent ref="formAutomate"
          v-model="validAutomate"
          lazy-validation >
            <div v-if="currentRule" class="pa-0 ma-0">
              <div v-for="action in JSON.parse(currentRule.data).modules" :key="action">
                <hr class="hr-text" :data-content="$t(modules.filter(m=>m.value==action)[0].text)">
                <div v-if="modules.filter(m=>m.value==action)[0].value == 'D'">
                  <v-autocomplete outlined dense v-model="selectedDoc" :items="listDocs" item-text="name" item-value="id" clearable
                    class="white pa-0 my-3" hide-details :label="$t('Document Name')" :placeholder="$t('Document Name')" @change="checkField(selectedDoc,'')" :rules="[rules.required]"></v-autocomplete>
                </div>
                <div v-if="modules.filter(m=>m.value==action)[0].value == 'E'">
                  <v-text-field
                    v-model="sendTo"
                    outlined
                    :label="$t('from')"
                    :placeholder="$t('from')"
                    class="white pa-0 my-3"
                    hide-details
                    dense
                    :rules="[rules.required]"
                    disabled
                  ></v-text-field>                
                </div>
                <div v-if="activity == JSON.parse(currentRule.data).activity">
                  <div
                    v-for="field in JSON.parse(currentRule.data).fields"
                    :key="field"
                  >
                    <!-- Check field type -->
                    <div v-if="getFieldData(action, field).LookUpInt == 0">
                      <v-autocomplete
                        :items="
                          getLookupField(
                            getFieldData(action, field).LookUpKey
                          )
                        "
                        outlined
                        :label="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        :placeholder="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        dense
                        clearable
                        class="white pa-0 my-3"
                        hide-details
                        v-model="
                          fields[getFieldData(action, field).LookUpOther]
                        "
                        @change="checkField(fields[getFieldData(action, field).LookUpOther],getFieldData(action, field).LookUpKey)"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </div>

                    <div v-if="getFieldData(action, field).LookUpInt == 1">
                      <v-text-field
                        :label="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        :placeholder="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        :rules="[rules.required, rules.min, rules.max]"
                        outlined
                        class="white pa-0 my-3"
                        hide-details
                        dense
                        v-model="
                          fields[getFieldData(action, field).LookUpOther]
                        "
                      ></v-text-field>
                    </div>
                    <div v-if="getFieldData(action, field).LookUpInt == 2">
                      <v-menu
                        class="ma-0"
                        v-model="date"
                        :close-on-content-click="false"
                        :nudge-left="70"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              fields[
                                getFieldData(action, field).LookUpOther
                              ]
                            "
                            :label="
                              $i18n.locale == 'en'
                                ? getFieldData(action, field).LookUpStringE
                                : getFieldData(action, field).LookUpStringF
                            "
                            :placeholder="
                              $i18n.locale == 'en'
                                ? getFieldData(action, field).LookUpStringE
                                : getFieldData(action, field).LookUpStringF
                            "
                            prepend-inner-icon="mdi-calendar"
                            dense
                            readonly
                            outlined
                            class="white pa-0 my-3"
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            :rules="[rules.required]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            fields[getFieldData(action, field).LookUpOther]
                          "
                          @input="date = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <div v-if="getFieldData(action, field).LookUpInt == 3">
                      <v-combobox
                        v-model="fields[
                                getFieldData(action, field).LookUpOther
                              ]"
                        :items="getLookupField(getFieldData(action, field).LookUpKey)"
                        :search-input.sync="additional[getFieldData(action, field).LookUpOther]"
                        dense
                        persistent-hint
                        :label="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        :placeholder="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        outlined
                        class="white pa-0 my-3"
                        hide-details
                        small-chips
                        return-object 
                        :rules="[(getFieldData(action, field).LookUpOther=='cc'||getFieldData(action, field).LookUpOther=='bcc')?true:rules.requiredmultiple]"
                        @change="checkField(fields[getFieldData(action, field).LookUpOther],getFieldData(action, field).LookUpKey)"
                        multiple>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{$t("No results matching")}} "<strong>{{ additional[getFieldData(action, field).LookUpOther] }}</strong>". {{$t("Press")}} <kbd>{{$t("enter")}}</kbd> {{$t("to create a new one")}}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </div>

                    <div v-if="getFieldData(action, field).LookUpInt == 4">
                      <v-autocomplete
                        :items="
                          getLookupField(
                            getFieldData(action, field).LookUpKey
                          )
                        "
                        outlined
                        multiple
                        small-chips
                        :label="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        :placeholder="
                          $i18n.locale == 'en'
                            ? getFieldData(action, field).LookUpStringE
                            : getFieldData(action, field).LookUpStringF"
                        dense
                        clearable
                        class="white pa-0 my-3"
                        hide-details
                        v-model="
                          fields[getFieldData(action, field).LookUpOther]
                        "
                        @change="checkField(fields[getFieldData(action, field).LookUpOther],getFieldData(action, field).LookUpKey)"
                        :rules="[rules.requiredmultiple]"
                      ></v-autocomplete>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
            <a class="font-weight-medium text--secondary d-flex mx-auto pa-0 mb-1 hlink" v-else @click.stop="openInNew({name:'taskrules'})">{{$t('setrulesintaskrules')}}</a>
          </v-form>
        </div>
      </fieldset>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import { mapState,mapGetters,mapActions } from "vuex";

import { MODULES } from "@/helpers/exports";
export default {
  name: "AutomateActivity",
  props: ["details"],
  data() {
    return {
      additional: [],
      selectedDoc:null,
      user:null,
      date: false,
      validAutomate: false,
      activity: null,
      currentRule: null, 
      modules: MODULES,
      fields: [],
      sectors: [],
      lang: localStorage.getItem("language"),
      rules: {
        required: v => !!(v ) || this.$t("required"),
        requiredmultiple: v => !!(v && v.length) || this.$t("required"),
        min: value => (value && value.length >= 3) || this.$t('mincharacters3'),
        max: value => (value && value.length <= 200) || this.$t('maxcharacters200'),
      },
    };
  },
  async created() {
    this.$store.dispatch("taskrules/retriveTaskRules");
    this.$store.dispatch("documents/retrieveDocuments");
    this.$store.dispatch("address/retrieveAddresses");
    await this.retriveTemplate();
    await this.getSectors();
    this.user=JSON.parse(localStorage.getItem('user'));
  },
  computed: {
    ...mapState("configuration", {
      configurations: "configurations",
    }),
    ...mapState("documents",{
      listDocs: "documents"
    }),
    ...mapState("taskrules", {
      taskrules: "taskrules",
    }),
    ...mapState({
      users: "users"
    }),
    ...mapGetters('template', {
      templates: 'getTemplatebyLanguage'
    }),
    sendTo(){
      return this.user?.email+'('+this.user?.username+')';
    }    

  },
  watch:{
    details(val){
      if(val){
        this.activity=null;
        this.fields=[];
      }
    },
  },
  methods: {
    disableSaveBtn(val){
      this.$emit("disableSaveBtn", val);
    },
    async checkValidation(){
      if(!this.$refs.formAutomate.validate()){
        await this.disableSaveBtn(true)
      }
    },
    checkField(item,field){
      if(field=='allSector')
        this.applySectorInfo(item);
      if(field=='allAddress')
        this.validateInput(item);
      if(!this.$refs.formAutomate.validate())
        this.disableSaveBtn(true);
      this.updateParent()
    },
    applySectorInfo(item){
/*      
        this.fields.sendTo=[...new Set(this.sectors.filter(i=>item.includes(i.LookUpTableId)).map(j=>JSON.parse(j.LookUpOther)).flat().filter(k=>k!=''))];
        this.fields.cc=[...new Set(this.sectors.filter(i=>item.includes(i.LookUpTableId)).map(j=>JSON.parse(j.LookUpFileName1)).flat().filter(k=>k!=''))];
        this.fields.bcc=[...new Set(this.sectors.filter(i=>item.includes(i.LookUpTableId)).map(j=>JSON.parse(j.LookUpFileName2)).flat().filter(k=>k!=''))];
*/ 
    this.fields.sendTo=this.sectors.filter(i=>item==i.LookUpTableId).map(j=>JSON.parse(j.LookUpOther)).flat().filter(k=>k!='');
    this.fields.cc=this.sectors.filter(i=>item==i.LookUpTableId).map(j=>JSON.parse(j.LookUpFileName1)).flat().filter(k=>k!='');
    this.fields.bcc=this.sectors.filter(i=>item==i.LookUpTableId).map(j=>JSON.parse(j.LookUpFileName2)).flat().filter(k=>k!='');
    console.log(this.fields);
    },
    validateInput(item){
      if(item.length){
          let lastEle = item.pop();
          let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
          if(regex.exec(lastEle)){
            item.push(lastEle);
            this.disableSaveBtn(false)
          }
      }
      else{
        this.disableSaveBtn(true);
      }
    },
    getFieldData(action, key) {
      let column='FManager';
      return (
        this.configurations.filter(
          (filter) => filter.LookUpName === column && filter.LookUpFlag === action && filter.LookUpOther == key
        )[0] || ""
      );
    },
    getSectors(){
      this.sectors=this.configurations.filter( filter => filter.LookUpName === 'SManager' );
    },
    getLookup(column) {
      return this.configurations
        .filter((filter) => filter.LookUpName === column)
        .map((item) => {
          let arr = [];
          arr["text"] =
            localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    getLookupField(field) {
      if(field=='allAddress')
        return Array.from(new Set(this.$store.state.address.addresses.map(i=>i.Email).concat(this.users.map(i=>i.email))));

      if(field=='allTemplate')
        return this.templates.map(template=>{return {value:template.id,text:template.name}});

      if(field=='allSector')
        return this.sectors.map(item=>{return {value:item.LookUpTableId,text:this.$i18n.locale == 'en'?item.LookUpStringE:item.LookUpStringF}});

      if(field=='allATIPOfficer'){
        let mappedUsers=this.users.map(item=>{return {value:item.id,text:item.last_name+' '+item.first_name}})
        let empty={value:'notassigned',text:this.$t("Empty")};
        mappedUsers.unshift(empty);
        return mappedUsers;
      }
      return this.configurations
        .filter((filter) => filter.LookUpName == field)
        .map((item) => {
          let arr = [];
          arr["text"] =
            localStorage.getItem("language") === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
    updateParent() {
      this.checkValidation();
      this.fields.docId = this.selectedDoc;
      this.$emit("automateTask", this.fields);
    },
    getTitle(){
      return this.configurations
        .filter(
          (filter) =>
            filter.LookUpKey === this.activity && filter.LookUpName === "Event"
        )
        .map((item) => {
          return localStorage.getItem("language") === "en"
            ? item.LookUpStringE
            : item.LookUpStringF;
        });
    },
    setTitle() {
      // Set Title
      this.updateParent();
      if(this.activity)
        this.currentRule=this.taskrules.filter(item=>item.activity==this.activity)[0]
      this.$emit("input", this.activity);
      let title = this.getTitle();
      if (this.activity === "Approval") {
        this.$emit("setType", 3);
      }
      this.$emit("setTitle", title[0]);
      this.$nextTick(() => {   
        if(!this.$refs.formAutomate.validate()){
          this.disableSaveBtn(true)
        }
      })
    },
    openInNew(r){
      let routeData = this.$router.resolve(r);
      window.open(routeData.href, '_blank');
    },
    ...mapActions('template',['retriveTemplate']),
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  line-height: 1em;
  margin-bottom: 10px;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: white;
    background-color: rgb(109, 109, 109)
  }
} 

.hlink{
  width: fit-content;
  text-decoration: underline;
}
.hlink:hover{
  color:#046e8b !important;
}
.text-wrap{
    word-break: break-word;
}
</style>