<template>
    <div :class="$vuetify.full?'pa-0 ma-0 my-border task-info-full':'pa-0 ma-0 my-border task-info'" id="task-info2">
        <div class="pa-0 ma-0 white" style="height:100%;">
            <v-toolbar height="55" class="gray px-3 ma-0 elevation-0">
            <v-toolbar-title>{{$t(title)}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="closeDetails()" icon>
                <v-icon class=" font-weight-light pa-0 ma-0">mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <div class="pa-0 ma-0" style="height:calc(100% - 110px); overflow-y:auto">
            <v-row class="pa-0 ma-0" style="min-height:100%;">
              <v-col cols="12" class="text-center">
                <v-form v-on:submit.prevent ref="form" v-model="valid" lazy-validation>
                  <automate-activity
                    :key='activityKey'
                    @setTitle="setTitle"
                    :details="details"
                    @automateTask="setAutomate"
                    @disableSaveBtn="disableSaveBtn"
                    class="mb-5"
                    ></automate-activity>
                  <v-text-field :label="$t('title')" :placeholder="$t('title')" :rules="[rules.required, rules.min, rules.max]" outlined dense v-model="localtask.title"></v-text-field>
                  <v-autocomplete
                      :label="$t('labels')"
                      :placeholder="$t('labels')"
                      small-chips
                      multiple
                      dense
                      :items="getLookup('TaskLabel')"
                      item-text="text"
                      outlined
                      item-value="value"
                      autowidth
                      v-model="localtask.label"
                  >
                      <template v-slot:selection="data">
                        <v-chip :input-value="data.selected" small :color="setcolor(data.item.value)">
                            <strong>{{ getStatusLookup('TaskLabel', data.item.value)[0] }}</strong>
                        </v-chip>
                      </template>
                  </v-autocomplete>
                  <v-menu 
                      class="ma-0"
                      v-model="due"
                      :close-on-content-click="false"
                      :nudge-left="50"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="localtask.due_date"
                          :label="$t('Due Date')"
                          :placeholder="$t('Due Date')"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          :rules="[rules.required]"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="localtask.due_date" :min="nowDate" @input="due = false"></v-date-picker>
                  </v-menu>
                  <v-autocomplete
                    :items="requests"
                    :label="$t('requestid')"
                    :placeholder="$t('requestid')"
                    item-value="id"
                    outlined
                    item-text="reqCode" 
                    dense
                    clearable
                    :rules="[rules.required]"
                    v-model="localtask.request_id"
                  ></v-autocomplete>
                  <v-autocomplete
                      :items="users"
                      item-value="id"
                      :label="$t('assignee')"
                      :placeholder="$t('assignee')"
                      outlined
                      dense
                      v-model="localtask.assigned_to"
                      clearable
                  >
                    <template slot="selection" slot-scope="data">
                      <span class="text-capitalize">{{ data.item.first_name+' '+ data.item.last_name}}</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span class="text-capitalize">{{ data.item.first_name+' '+ data.item.last_name}}</span>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete 
                      :items="getLookup('TaskType').slice(0,3)"
                      :label="$t('type')"
                      :placeholder="$t('type')"
                      outlined
                      :rules="[rules.required]"
                      dense
                      v-model="localtask.type">
                  </v-autocomplete>
                  <v-textarea
                      counter
                      auto-grow
                      outlined
                      row-height="20"
                      :label="$t('description')"
                      :placeholder="$t('description')"
                      v-model="localtask.description"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
            </div>
            <v-divider></v-divider>
            <div
            height="55"
            class="justify-center  ma-0 pa-0" 
            >
            <div class="gray ma-0 pa-0 py-2 text-center" style="width:100%;">
            <v-btn  
            class="custom-color-accent"
                depressed
                :loading="loading4"
                :disabled="loading4 || SaveBtn"
                @click="createTask()"
            >
            <span>{{$t("Add Task")}}</span>
                <template v-slot:loader>
                <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                </span>
                </template>
            </v-btn>
            </div>
            
            </div>
        </div>
    </div>
</template>
<script>
import {  mapState,mapActions,mapGetters } from "vuex";
import moment from 'moment';
import AutomateActivity from "@/components/task/AutomateActivity";
export default {
    props: ["details","task"],
    components: {
      'automate-activity': AutomateActivity
    },
   data() {
        return {
        nowDate: new moment().toISOString(),
        loader: null,
        loading4: false,
        SaveBtn: false,
        valid: true,
        activityKey: Date.now(),
        title:'Add Task',
        localtask:{},
        due: false,
        rules: {
              required: value => !!value || this.$t('required'),
              min: value => (value && value.length >= 3) || this.$t('mincharacters3'),
              max: value => (value && value.length <= 200) || this.$t('maxcharacters200'),
          },
        autoTask:{}
       }
    },
    watch: {
        loader() {
        const l = this.loader;
        this[l] = !this[l];
        this.loader = null;
        },
        details(val){
          if(val){
            this.showEditSidebar()
          }
        },
        task:{ handler(val){
          this.localtask={}
          Object.assign(this.localtask,val)
          this.resetActivity();
          }, deep: true
        },
        localtask:{ handler(val){
          if(val.label&&!Array.isArray(val.label))
          this.localtask.label = JSON.parse( val.label )
          }, deep: true
        },
    },
    async created(){
      Object.assign(this.localtask,this.task)
      await this.retriveRequests();
      await this.retriveUsers();
      await this.retriveTemplate()
    },
    methods: {
      ...mapActions([ "retriveUsers"]),   
      ...mapActions('request', ['retriveRequests']),
      resetActivity(){
        this.activityKey=Date.now();
      },
      setTitle(activity_title){
        this.task.title=activity_title; 
        this.localtask={}
        Object.assign(this.localtask,this.task)
      },
      setAutomate(automate){
        this.autoTask = automate;
      },
      disableSaveBtn(val){
        this.SaveBtn = val;
      },
      parseLabel( label ){
        return JSON.parse( label );
      },
      showEditSidebar() {
          this.$root.$emit("setTaskSidebar", true);
          let element = document.getElementById("task-info2");
          element.setAttribute( 'style', 'width:250px !important;' );
          if (window.innerWidth < 600) {
            element.setAttribute( 'style', 'width:100% !important;' );
          }
      },
      closeDetails() {
        let element = document.getElementById("task-info2");
        element.setAttribute( 'style', 'width:0px !important;' );
        this.$emit('setDetails',false)
        this.$root.$emit("setTaskSidebar", false); 
      },
      filterContent(content){
        if(content){
          return content.replace(/\$(\w+.\w+)/g, (_, v) => {
            let string = v.split('.');
            let currentRequest = this.requests.filter(item=>item.id==this.localtask.request_id)[0];
            if( string.length >= 1 ){
              if(string[0] === 'address'){
                return currentRequest[string[0]][string[1]];
              }
              else if(string[0] == 'date'){
                return moment().format("YYYY-MM-DD");
              }
              else if(string[0] == 'user'){
                return this.user[string[1]];
              }else{
                return currentRequest[string[0]];
              }
            }
            else{
              return currentRequest[string[0]];
            }
          });
        }
      },
      createTask(){
        if(this.$refs.form.validate()){
          this.loader = 'loading4';
          this.postTask(this.localtask).then(async () => {
            let requestField = this.configurations
                .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'R')
                .map(item =>item.LookUpOther );
            let documentField = this.configurations
                .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'D')
                .map(item =>item.LookUpOther );
            let emailField = this.configurations
                .filter(filter => filter.LookUpName === 'FManager' && filter.LookUpFlag === 'E')
                .map(item =>item.LookUpOther );

                let allKey = Object.keys(this.autoTask);
                if( allKey.some((i)=>requestField.includes(i))){
                  let req={}
                  req.id = this.localtask.request_id;
                  req.RequestStatus = this.autoTask.requestStatus;
                  req.SecurityClass = this.autoTask.requestSecurityClass;
                  req.DateDue = this.autoTask.requestDateDue;
                  req.RequestText = this.autoTask.requestText;
                  this.updateRequest(req);
                  }
                if( allKey.some((i)=>documentField.includes(i))){
                  let document={}
                  document.id = this.autoTask.docId;
                  document.status = this.autoTask.documentStatus;
                  this.updateDocument(document);
                }
                if( allKey.some((i)=>emailField.includes(i))){
                  let template = await this.templates.filter( item => item.id === this.autoTask.emailTemplate )[0];
                  let newemail={
                    requestId:null,
                    userId:JSON.parse(localStorage.getItem('user')).id,
                    date_sent:moment().format("YYYY-MM-DD"),
                    data:{
                      sendTo:[],
                      subject:"",
                      body:"",
                      replyTo:JSON.parse(localStorage.getItem('user')).email,
                      cc:[],
                      bcc:[],
                      attachments:[]
                    },
                    user:JSON.parse(localStorage.getItem('user') || null)
                  };
                  newemail.data.sendTo = this.autoTask.sendTo;
                  newemail.data.cc = this.autoTask.cc?this.autoTask.cc:[];
                  newemail.data.bcc = this.autoTask.bcc?this.autoTask.bcc:[];
                  newemail.data.body = this.filterContent(template.content);
                  newemail.data.subject = this.filterContent(template.name);
                  const formData = new FormData();
                  formData.append('requestId', this.localtask.request_id);
                  formData.append('userId', JSON.parse(localStorage.getItem('user')).id);
                  formData.append('date_sent', moment().format("YYYY-MM-DD"));
                  formData.append( 'sendTo', newemail.data.sendTo );
                  formData.append( 'attachment', newemail.data.attachments );
                  formData.append( 'subject', newemail.data.subject );
                  formData.append( 'replyTo', JSON.parse(localStorage.getItem('user')).email );
                  formData.append( 'cc', newemail.data.cc );
                  formData.append( 'bcc', newemail.data.bcc );
                  formData.append( 'user', JSON.parse(localStorage.getItem('user')) );
                  formData.append( 'body', newemail.data.body );
                  this.sendEmail(formData);
                }
                this.closeDetails();
                this.loading4 = false; 
                let item = {text:this.$t("taskAddAlert"), type:"success", alert:true};
                this.$root.$emit("callAlert", item);
          })
        }
        else{
          this.loading4=false;
          this.loader=false;
        }
      },
      setcolor(status) {
          if (status === "done") {
              return "success";
          } else if (status === "onHold") {
            return "warning";
          } else if (status === "ongoing") {
            return "primary";
          } else if (status === "todo") {
            return "gray";
          } else if (status === "high") {
            return "error";
          } else if (status === "medium") {
            return "warning";
          } else if (status === "low") {
            return "gray";
          } else{
            return "gray"
          }
      },
      getStatusLookup(column = 'TaskLabel', label){
        return this.configurations
          .filter(filter => filter.LookUpName === column && filter.LookUpKey == label)
          .map(item => {
            
              return localStorage.getItem("language") === "en"
                ? item.LookUpStringE
                : item.LookUpStringF;
            
          });
      },
      getLookup(column) {
          return this.configurations
              .filter(filter => filter.LookUpName === column)
              .map(item => {
              let arr = [];
              arr["text"] =
                  localStorage.getItem("language") === "en"
                  ? item.LookUpStringE
                  : item.LookUpStringF;
              arr["value"] = item.LookUpKey;
              return arr;
              });
      },
      ...mapActions("task", ["postTask"]),
      ...mapActions("request", ["updateRequest"]),
      ...mapActions("documents",['updateDocument']),
      ...mapActions("email", ["sendEmail"]),
      ...mapActions('template',['retriveTemplate']),
    },
    computed: {
    ...mapState("request", {
      requests: "requests"
    }),
    ...mapState({
      users: "users"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
    ...mapGetters('template', {
      templates: 'getTemplatebyLanguage'
    }),
    ...mapState(['user']),
  },
}
</script>
<style scoped>
.task-info {
  position: fixed;
  height: calc(100% - 53px);
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 0;
}
.task-info-full{
  position: fixed;
  height:100%;
  right: 0px;
  z-index: 1;
  bottom: 0;
  width: 0;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
 
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>